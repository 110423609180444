import BaseService from "./BaseService";

const api = "data-source/";

export function getPortsOfLanding() {
    return BaseService.get(`${api}ports-of-landing`);
}

export function getLandingZones() {
    return BaseService.get(`${api}landing-zones`);
}

export function getRegistrationPort() {
    return BaseService.get(`${api}registration-ports`);
}

export function getFishingArtToFishermen() {
    return BaseService.get(`${api}fishing-arts-fisherman`);
}
export function getTypeOfFisheryFishermen() {
    return BaseService.get(`${api}fishing-type-of-fishery-fisherman`);
}
export function getRequestTypes() {
    return BaseService.get(`${api}company-request-types`);
}
export function getActivityTimes() {
    return BaseService.get(`${api}fishing-activity-times`);
}

export function getFishingArts() {
    return BaseService.get(`${api}fishing-arts`);
}

export function getTypesOfFishery() {
    return BaseService.get(`${api}types-of-fishery`);
}

export function getAuthorizedArts() {
    return BaseService.get(`${api}authorized-arts`);
}