<template>
  <div>
    <v-card>
      <v-toolbar class="indigo darken-4 white--text" dark>
        <v-icon class="mx-4" @click="$router.go(-1)">arrow_back</v-icon>
        <v-toolbar-title
          >Formulario Investigaciones por Violación al Estatuto
          Pesquero</v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-dialog
          :readonly="process != 'new'"
          v-model="dialogBoat"
          persistent
          max-width="1500px"
        >
          <v-card>
            <v-toolbar color="indigo darken-4 white--text" dark>
              <v-toolbar-title>Embarcación</v-toolbar-title>

              <v-spacer></v-spacer>
            </v-toolbar>

            <v-card-text>
              <v-stepper :readonly="process != 'new'" v-model="e1" class="ma-4">
                <v-stepper-header>
                  <v-stepper-step :complete="e1 > 1" step="1"
                    >IDENTIFICACIÓN</v-stepper-step
                  >

                  <v-divider></v-divider>

                  <v-stepper-step :complete="e1 > 2" step="2"
                    >DATOS DE REGISTRO</v-stepper-step
                  >

                  <v-divider></v-divider>

                  <v-stepper-step :complete="e1 > 3" step="3"
                    >DIMENSIONES</v-stepper-step
                  >

                  <v-divider></v-divider>
                </v-stepper-header>

                <v-stepper-items>
                  <v-stepper-content step="1">
                    <v-card class="mb-12" color="white lighten-1">
                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col cols="6" sm="12" md="6">
                              <v-text-field
                                :readonly="process != 'new'"
                                v-model="external_marking"
                                label="Marcador Externo - Número de Matricula*"
                                required
                              ></v-text-field>
                            </v-col>
                            <v-col cols="6" sm="12" md="6">
                              <v-text-field
                                :readonly="process != 'new'"
                                v-model="vessel_name"
                                label="Nombre del barco"
                                hint="Nombre del barco"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-container>
                        <small>* Indicador de campo requerido</small>
                      </v-card-text>
                    </v-card>

                    <v-btn
                      color="primary"
                      :disabled="!external_marking"
                      @click="e1 = 2"
                      >Continuar</v-btn
                    >
                  </v-stepper-content>
                  <v-stepper-content step="2">
                    <v-card class="mb-12" color="white lighten-1">
                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col cols="6" sm="12" md="6">
                              <v-select
                                :readonly="process != 'new'"
                                v-model="select_current_state_pavilion"
                                prepend-inner-icon="map"
                                hint="Estado actual del pabellón - BANDERA"
                                :items="items_select_current_state_pavilion"
                                item-text="state"
                                item-value="abbr"
                                label="Estado actual del pabellón - BANDERA"
                                persistent-hint
                                return-object
                                single-line
                              ></v-select>
                            </v-col>
                          </v-row>
                        </v-container>
                        <small>* Indicador de campo requerido</small>
                      </v-card-text>
                    </v-card>

                    <v-btn color="primary" @click="e1 = 3">Continuar</v-btn>

                    <v-btn text @click="e1 = 1">Regresar</v-btn>
                  </v-stepper-content>
                  <v-stepper-content step="3">
                    <v-card class="mb-12" color="white lighten-1">
                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col cols="4" md="4" sm="12">
                              <v-text-field
                                :readonly="process != 'new'"
                                v-model="eslora_length"
                                prepend-inner-icon
                                placeholder="ESLORA - Longitud total (LOA)(m)"
                                hint="ESLORA - Longitud total (LOA)(m)"
                                persistent-hint
                                suffix="m"
                                type="number"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="4" md="4" sm="12">
                              <v-text-field
                                :readonly="process != 'new'"
                                v-model="extreme_breadth"
                                prepend-inner-icon
                                placeholder="Manga / extrema amplitud (m)"
                                suffix="m"
                                hint="Manga / extrema amplitud (m)"
                                persistent-hint
                                type="number"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="4" sm="12" md="4">
                              <v-text-field
                                :readonly="process != 'new'"
                                v-model="registration_tonnage"
                                prepend-inner-icon
                                placeholder="Tonelaje de registro neto (TRN)"
                                suffix="TRN"
                                hint="Tonelaje de registro neto (TRN)"
                                persistent-hint
                                type="number"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="4" sm="12" md="4">
                              <v-text-field
                                :readonly="process != 'new'"
                                v-model="registration_tonnage_gross"
                                prepend-inner-icon
                                placeholder="Tonelaje de registro bruto (TRB)"
                                suffix="TRB"
                                hint="Tonelaje de registro bruto (TRB)"
                                persistent-hint
                                type="number"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="4" sm="12" md="4">
                              <v-select
                                :readonly="process != 'new'"
                                v-model="select_propulsion_type"
                                prepend-inner-icon="map"
                                :items="items_select_propulsion_type"
                                item-text="state"
                                item-value="abbr"
                                label="Tipo de propulsión"
                                hint="Elementos o equipos que permiten desplazar la embarcación (motor, remos, vela, etc.)."
                                return-object
                                single-line
                              ></v-select>
                            </v-col>
                            <v-col cols="4" sm="12" md="4">
                              <v-text-field
                                :readonly="process != 'new'"
                                v-model="main_motor_power"
                                prepend-inner-icon
                                placeholder="Potencia del motor principal/s"
                                clearable
                                hint="Potencia del motor principal/s"
                                persistent-hint
                                type="number"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="4" sm="12" md="4">
                              <v-select
                                :readonly="process != 'new'"
                                v-model="select_power_unit"
                                prepend-inner-icon="map"
                                hint="Unidad de Potencia"
                                :items="items_select_power_unit"
                                item-text="state"
                                item-value="abbr"
                                label="Unidad de Potencia"
                                persistent-hint
                                return-object
                                single-line
                              ></v-select>
                            </v-col>
                            <v-col cols="6" sm="12" md="6"></v-col>
                          </v-row>
                        </v-container>
                        <small>* Indicador de campo requerido</small>
                      </v-card-text>
                    </v-card>

                    <v-btn text @click="e1 = 2">Regresar</v-btn>
                  </v-stepper-content>
                </v-stepper-items>
              </v-stepper>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialogBoat = false"
                >Cerrar</v-btn
              >
              <v-btn
                color="indigo darken-4 white--text"
                v-if="e1 === 3"
                @click="saveBoat"
                >Guardar</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
      <v-container grid-list-sm>
        <v-layout row wrap>
          <v-flex xs12 class="mb-4">
            <v-subheader light class="subtitle-1 font-italic font-weight-bold"
              >INVESTIGACIONES POR VIOLACION AL ESTATUTO PESQUERO</v-subheader
            >
            <v-divider light></v-divider>
          </v-flex>
          <v-flex xs12 class="mb-4">
            <v-subheader light class="subtitle-1 font-italic font-weight-bold"
              >DATOS DE SOLICITUD</v-subheader
            >
            <v-divider light></v-divider>
          </v-flex>

          <v-flex xs6>
            <v-select
              :readonly="process != 'new'"
              v-model="location"
              prepend-inner-icon="map"
              hint="Lugar de la solicitud"
              :items="items_location"
              item-text="state"
              item-value="abbr"
              label="Lugar de la solicitud"
              persistent-hint
              return-object
              single-line
            ></v-select>
          </v-flex>
          <v-flex xs12 class="mb-4" v-if="Statute.permisionario != null">
            <v-subheader light class="subtitle-1 font-italic font-weight-bold">
              DATOS DE LA EMPRESA
              <v-spacer></v-spacer>
              <v-dialog
                :readonly="process != 'new'"
                v-model="dialogNewCompany"
                persistent
                max-width="850"
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-if="process == 'new'"
                    small
                    color="indigo darken-4 white--text"
                    dark
                    v-on="on"
                  >
                    <v-icon small>fa fa-edit</v-icon> Editar
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title
                    class="headline indigo darken-4 white--text"
                    primary-title
                    >Editar Empresa</v-card-title
                  >
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="6">
                          <v-select
                            :readonly="process != 'new'"
                            v-model="select_type_company_modal"
                            prepend-inner-icon="map"
                            hint="Tipo de empresa"
                            :items="items_select_type_company_modal"
                            item-text="state"
                            item-value="abbr"
                            label="Tipo de empresa"
                            persistent-hint
                            return-object
                            single-line
                          ></v-select>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field
                            :readonly="process != 'new'"
                            v-model="nit_modal"
                            prepend-inner-icon="business"
                            placeholder="NIT"
                            clearable
                            hint="NIT"
                            persistent-hint
                          ></v-text-field>
                        </v-col>
                        <v-col cols="2">
                          <v-text-field
                            :readonly="process != 'new'"
                            v-model="cod_nit"
                            hint="Código de Verificación"
                            persistent-hint
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            :readonly="process != 'new'"
                            v-model="name_modal"
                            prepend-inner-icon="fa-industry"
                            placeholder="PERMISIONARIO Y/O TITULAR DEL PERMISO"
                            clearable
                            hint="Nombre de la empresa o persona natural"
                            persistent-hint
                          ></v-text-field>
                        </v-col>

                        <v-col cols="6">
                          <v-text-field
                            :readonly="process != 'new'"
                            v-model="legal_representative_modal"
                            prepend-inner-icon="fa-user"
                            placeholder="Nombre del representante legal"
                            clearable
                            hint="Nombre del representante legal"
                            persistent-hint
                          ></v-text-field>
                        </v-col>

                        <v-col cols="6">
                          <v-combobox
                            :readonly="process != 'new'"
                            v-model="select_id_type_modal"
                            :items="items_select_id_type_modal"
                            hide-selected
                            item-text="state"
                            item-value="abbr"
                            hint="Si el tipo de identificación no está en la lista, escríbalo y de click en ENTER"
                            label="Tipo de identificación"
                            persistent-hint
                            small-chips
                          >
                          </v-combobox>
                        </v-col>

                        <v-col cols="6">
                          <v-text-field
                            :readonly="process != 'new'"
                            v-model="identification_number_modal"
                            prepend-inner-icon="fa-bars"
                            placeholder="Número de identificación"
                            clearable
                            hint="Número de identificación"
                            persistent-hint
                          ></v-text-field>
                        </v-col>

                        <v-col cols="6">
                          <v-text-field
                            :readonly="process != 'new'"
                            v-model="address_modal"
                            prepend-inner-icon="fa-map-pin"
                            placeholder="Dirección"
                            clearable
                            hint="Dirección"
                            persistent-hint
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field
                            :readonly="process != 'new'"
                            v-model="phone_modal"
                            prepend-inner-icon="fa-phone"
                            placeholder="No teléfono"
                            clearable
                            hint="No teléfono"
                            persistent-hint
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="dialogNewCompany = false"
                      >Cerrar</v-btn
                    >
                    <v-btn
                      color="indigo darken-4 white--text"
                      dark
                      @click="saveCompany"
                      >Crear</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-subheader>
            <v-alert v-if="alertNotFoundCompany" type="info" dismissible
              >No se encontro el Permisionario.</v-alert
            >
            <v-divider light></v-divider>
          </v-flex>
          <v-flex xs6 v-if="Statute.permisionario != null">
            <v-select
              :readonly="process != 'new'"
              v-model="select_type_of_company"
              prepend-inner-icon="map"
              hint="Tipo de empresa"
              :items="items_select_type_of_company"
              item-text="state"
              item-value="abbr"
              label="Tipo de empresa"
              persistent-hint
              return-object
              disabled
              single-line
            ></v-select>
          </v-flex>
          <v-flex xs6 v-if="Statute.permisionario != null">
            <v-text-field
              :readonly="process != 'new'"
              v-model="name"
              prepend-inner-icon="fa-user"
              hint="Nombre de la Empresa"
              clearable
              persistent-hint
              disabled
            ></v-text-field>
          </v-flex>
          <v-flex xs4 v-if="Statute.permisionario != null">
            <v-text-field
              :readonly="process != 'new'"
              v-model="nit"
              prepend-inner-icon="business"
              hint="NIT"
              clearable
              persistent-hint
              disabled
            ></v-text-field>
          </v-flex>
          <v-flex xs2 v-if="Statute.permisionario != null">
            <v-text-field
              :readonly="process != 'new'"
              v-model="cod_nit"
              hint="Código de Verificiación"
              persistent-hint
              disabled
            ></v-text-field>
          </v-flex>
          <v-flex xs6 v-if="Statute.permisionario != null">
            <v-text-field
              :readonly="process != 'new'"
              v-model="legal_representative"
              prepend-inner-icon="fa-user"
              hint="Nombre del representante legal"
              clearable
              disabled
              persistent-hint
            ></v-text-field>
          </v-flex>
          <v-flex xs6 v-if="Statute.permisionario != null">
            <v-select
              :readonly="process != 'new'"
              v-model="select_type_identification"
              prepend-inner-icon="map"
              hint="Tipo de identificación"
              :items="items_select_type_identification"
              item-text="state"
              item-value="abbr"
              label="Tipo de identificación"
              persistent-hint
              return-object
              single-line
              disabled
            ></v-select>
          </v-flex>
          <v-flex xs6 v-if="Statute.permisionario != null">
            <v-text-field
              :readonly="process != 'new'"
              v-model="identification_number"
              prepend-inner-icon="business"
              hint="Número de identificación"
              clearable
              disabled
              persistent-hint
              type="number"
            ></v-text-field>
          </v-flex>
          <v-flex xs6 v-if="Statute.permisionario != null">
            <v-text-field
              :readonly="process != 'new'"
              v-model="address"
              prepend-inner-icon="my_location"
              hint="Dirección"
              clearable
              disabled
              persistent-hint
            ></v-text-field>
          </v-flex>

          <v-flex xs6 v-if="Statute.permisionario != null">
            <v-text-field
              :readonly="process != 'new'"
              v-model="phone"
              prepend-inner-icon="fa-phone"
              placeholder="No telefono"
              clearable
              disabled
              hint="No teléfono"
              persistent-hint
            ></v-text-field>
          </v-flex>
          <v-flex xs12 class="mb-4" v-if="showPermissionIn">
            <v-subheader light class="subtitle-1 font-italic font-weight-bold">
              INFORMACIÓN DEL PERMISO DE PESCA
              <v-spacer></v-spacer>
            </v-subheader>
          </v-flex>

          <v-flex xs12 class="mb-4" v-if="Statute.fisherman != null">
            <v-subheader light class="subtitle-1 font-italic font-weight-bold">
              DATOS DEL PESCADOR
              <v-spacer></v-spacer>
              <v-dialog
                :readonly="process != 'new'"
                v-model="dialogNewCompany"
                persistent
                max-width="850"
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    color="indigo darken-4 white--text"
                    dark
                    v-on="on"
                  >
                    <v-icon small>fa fa-edit</v-icon> Editar
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title
                    class="headline indigo darken-4 white--text"
                    primary-title
                    >Editar
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-flex xs6 v-if="Statute.fisherman != null">
                          <v-text-field
                            prepend-inner-icon="fa-user"
                            placeholder="Nombre(s) del Pescador"
                            hint="Nombre(s) del Pescador"
                            :readonly="process != 'new'"
                            v-model="name"
                            :clearable="process == '!resolv'"
                            persistent-hint
                          ></v-text-field>
                        </v-flex>

                        <v-flex xs6 v-if="Statute.fisherman != null">
                          <v-text-field
                            prepend-inner-icon="fa-user"
                            placeholder="Apellido(s) del Pescador"
                            hint="Apellido(s) del Pescador"
                            :readonly="process != 'new'"
                            v-model="lastname"
                            :clearable="process == '!resolv'"
                            persistent-hint
                          ></v-text-field>
                        </v-flex>

                        <v-flex xs6 v-if="Statute.fisherman != null">
                          <v-combobox
                            :readonly="process != 'new'"
                            v-model="select_type_document"
                            :items="items_select_type_document"
                            hide-selected
                            item-text="state"
                            item-value="abbr"
                            hint="Tipo de Documento de Identificación Personal"
                            label="Tipo de Documento de Identificación Personal"
                            persistent-hint
                            small-chips
                          ></v-combobox>
                        </v-flex>

                        <v-flex xs6 v-if="Statute.fisherman != null">
                          <v-text-field
                            :readonly="process != 'new'"
                            v-model="identification_number"
                            prepend-inner-icon="fa-id-card"
                            label="Número de Identificación"
                            :clearable="process == '!resolv'"
                            hint="Número de Identificación"
                            persistent-hint
                          ></v-text-field>
                        </v-flex>

                        <v-flex xs4 v-if="Statute.fisherman != null">
                          <v-select
                            :readonly="process != 'new'"
                            v-model="select_type_person"
                            prepend-inner-icon="map"
                            hint="Tipo de persona"
                            :items="items_select_research_type_person"
                            item-text="state"
                            item-value="abbr"
                            label="Tipo de persona"
                            persistent-hint
                            return-object
                            single-line
                          ></v-select>
                        </v-flex>

                        <v-flex xs4 v-if="Statute.fisherman != null">
                          <v-radio-group
                            :readonly="process != 'new'"
                            v-model="occre"
                            row
                            hint="Clasificación certificación OCCRE"
                            persistent-hint
                          >
                            <v-radio label="Raizal" value="RAIZAL"></v-radio>
                            <v-radio
                              label="Residente"
                              value="RESIDENTE"
                            ></v-radio>
                            <v-radio label="No Aplica" value="NA"></v-radio>
                            <v-radio
                              v-if="type == 'ind'"
                              label="Permiso de Trabajo"
                              value="PERMISO_TRABAJO"
                            ></v-radio>
                          </v-radio-group>
                        </v-flex>

                        <v-flex
                          xs4
                          v-if="Statute.fisherman != null && occre != 'NA'"
                        >
                          <v-text-field
                            :readonly="process != 'new'"
                            v-model="no_occre"
                            prepend-inner-icon="fa-barcode"
                            placeholder="No. OCCRE"
                            label="No. OCCRE"
                            :clearable="process == '!resolv'"
                            hint="Número de serie unico que identifica a una persona raizal y/o residente en el Departamento Archipielago de San Andrés, Providencia y Santa Catalina"
                          ></v-text-field>
                        </v-flex>

                        <v-flex xs4 v-if="Statute.fisherman != null">
                          <v-select
                            :readonly="process != 'new'"
                            v-model="select_nationality"
                            prepend-inner-icon="fa-flag"
                            persistent-hint
                            hint="Nacionalidad"
                            :items="items_select_nationality"
                            item-text="state"
                            item-value="abbr"
                            label="Nacionalidad"
                            return-object
                            single-line
                          ></v-select>
                        </v-flex>
                        <v-flex v-if="Statute.fisherman != null">
                          <v-text-field
                            :readonly="process != 'new'"
                            v-model="email"
                            prepend-inner-icon="email"
                            placeholder="Email"
                            label="Email"
                            :clearable="process == '!resolv'"
                            persistent-hint
                          ></v-text-field>
                        </v-flex>

                        <v-flex xs6 v-if="Statute.fisherman != null">
                          <v-text-field
                            :readonly="process != 'new'"
                            v-model="address"
                            prepend-inner-icon="fa-map-marker-alt"
                            placeholder="Dirección de la Residencia"
                            label="Dirección de la Residencia"
                            :clearable="process == '!resolv'"
                            persistent-hint
                          ></v-text-field>
                        </v-flex>

                        <v-flex xs6 v-if="Statute.fisherman != null">
                          <v-text-field
                            :readonly="process != 'new'"
                            v-model="phone"
                            prepend-inner-icon="phone"
                            placeholder="Número de Teléfono"
                            label="Número de Teléfono"
                            :clearable="process == '!resolv'"
                            persistent-hint
                          ></v-text-field>
                        </v-flex>

                        <v-flex
                          xs6
                          v-if="Statute.fisherman != null && occre != 'NA'"
                        >
                          <v-radio-group
                            :readonly="process != 'new'"
                            v-model="organization_cooperative"
                            :mandatory="false"
                            row
                            persistent-hint
                            hint="Organización / Cooperativa"
                          >
                            <v-radio label="Si" value="SI"></v-radio>
                            <v-radio label="No" value="NO"></v-radio>
                          </v-radio-group>
                        </v-flex>

                        <v-flex
                          xs4
                          v-if="Statute.fisherman != null && occre != 'NA'"
                        >
                          <v-select
                            :readonly="process != 'new'"
                            v-model="select_organization"
                            :items="itemsSelectOrganizations"
                            item-text="state"
                            item-value="abbr"
                            hint="Nombre de la organización"
                            persistent-hint
                            small-chips
                          ></v-select>
                        </v-flex>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="dialogNewCompany = false"
                      >Cerrar</v-btn
                    >
                    <v-btn
                      color="indigo darken-4 white--text"
                      dark
                      @click="saveFishermen"
                      >Guardar</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-subheader>
            <v-alert v-if="alertNotFoundCompany" type="info" dismissible
              >No se encontro el Permisionario.</v-alert
            >
            <v-divider light></v-divider>
          </v-flex>
          <v-flex xs6 v-if="Statute.fisherman != null">
            <v-text-field
              prepend-inner-icon="fa-user"
              placeholder="Nombre(s) del Pescador"
              hint="Nombre(s) del Pescador"
              disabled
              :readonly="process != 'new'"
              v-model="name_show"
              :clearable="process == '!resolv'"
              persistent-hint
            ></v-text-field>
          </v-flex>

          <v-flex xs6 v-if="Statute.fisherman != null">
            <v-text-field
              prepend-inner-icon="fa-user"
              placeholder="Apellido(s) del Pescador"
              hint="Apellido(s) del Pescador"
              disabled
              :readonly="process != 'new'"
              v-model="lastname_show"
              :clearable="process == '!resolv'"
              persistent-hint
            ></v-text-field>
          </v-flex>

          <v-flex xs6 v-if="Statute.fisherman != null">
            <v-combobox
              disabled
              :readonly="process != 'new'"
              v-model="select_type_document_show"
              :items="items_select_type_document"
              hide-selected
              item-text="state"
              item-value="abbr"
              hint="Tipo de Documento de Identificación Personal"
              label="Tipo de Documento de Identificación Personal"
              persistent-hint
              small-chips
            ></v-combobox>
          </v-flex>

          <v-flex xs6 v-if="Statute.fisherman != null">
            <v-text-field
              disabled
              :readonly="process != 'new'"
              v-model="identification_number_show"
              prepend-inner-icon="fa-id-card"
              label="Número de Identificación"
              :clearable="process == '!resolv'"
              hint="Número de Identificación"
              persistent-hint
            ></v-text-field>
          </v-flex>

          <v-flex xs4 v-if="Statute.fisherman != null">
            <v-select
              disabled
              :readonly="process != 'new'"
              v-model="select_type_person_show"
              prepend-inner-icon="map"
              hint="Tipo de persona"
              :items="items_select_research_type_person"
              item-text="state"
              item-value="abbr"
              label="Tipo de persona"
              persistent-hint
              return-object
              single-line
            ></v-select>
          </v-flex>

          <v-flex xs4 v-if="Statute.fisherman != null">
            <v-radio-group
              disabled
              :readonly="process != 'new'"
              v-model="occre_show"
              row
              hint="Clasificación certificación OCCRE"
              persistent-hint
            >
              <v-radio label="Raizal" value="RAIZAL"></v-radio>
              <v-radio label="Residente" value="RESIDENTE"></v-radio>
              <v-radio label="No Aplica" value="NA"></v-radio>

              <v-radio
                v-if="type == 'ind'"
                label="Permiso de Trabajo"
                value="PERMISO_TRABAJO"
              ></v-radio>
            </v-radio-group>
          </v-flex>

          <v-flex
            xs4
            v-if="Statute.fisherman != null && Statute.fisherman.occre != 'NA'"
          >
            <v-text-field
              disabled
              :readonly="process != 'new'"
              v-model="no_occre_show"
              prepend-inner-icon="fa-barcode"
              placeholder="No. OCCRE"
              label="No. OCCRE"
              :clearable="process == '!resolv'"
              hint="Número de serie unico que identifica a una persona raizal y/o residente en el Departamento Archipielago de San Andrés, Providencia y Santa Catalina"
            ></v-text-field>
          </v-flex>

          <v-flex xs4 v-if="Statute.fisherman != null">
            <v-select
              disabled
              :readonly="process != 'new'"
              v-model="select_nationality_show"
              prepend-inner-icon="fa-flag"
              persistent-hint
              hint="Nacionalidad"
              :items="items_select_nationality"
              item-text="state"
              item-value="abbr"
              label="Nacionalidad"
              return-object
              single-line
            ></v-select>
          </v-flex>
          <v-flex v-if="Statute.fisherman != null">
            <v-text-field
              disabled
              :readonly="process != 'new'"
              v-model="email_show"
              prepend-inner-icon="email"
              placeholder="Email"
              label="Email"
              :clearable="process == '!resolv'"
              persistent-hint
            ></v-text-field>
          </v-flex>
          <v-flex xs6 v-if="Statute.fisherman != null">
            <v-text-field
              disabled
              :readonly="process != 'new'"
              v-model="address_show"
              prepend-inner-icon="fa-map-marker-alt"
              placeholder="Dirección de la Residencia"
              label="Dirección de la Residencia"
              :clearable="process == '!resolv'"
              persistent-hint
            ></v-text-field>
          </v-flex>
          <v-flex xs6 v-if="Statute.fisherman != null">
            <v-text-field
              disabled
              :readonly="process != 'new'"
              v-model="phone_show"
              prepend-inner-icon="phone"
              placeholder="Número de Teléfono"
              label="Número de Teléfono"
              :clearable="process == '!resolv'"
              persistent-hint
            ></v-text-field>
          </v-flex>
          <v-flex
            xs6
            v-if="Statute.fisherman != null && Statute.fisherman.occre != 'NA'"
          >
            <v-radio-group
              disabled
              :readonly="process != 'new'"
              v-model="organization_cooperative_show"
              :mandatory="false"
              row
              persistent-hint
              hint="Organización / Cooperativa"
            >
              <v-radio label="Si" value="SI"></v-radio>
              <v-radio label="No" value="NO"></v-radio>
            </v-radio-group>
          </v-flex>
          <v-flex
            xs4
            v-if="Statute.fisherman != null && Statute.fisherman.occre != 'NA'"
          >
            <v-select
              disabled
              :readonly="process != 'new'"
              v-model="select_organization_show"
              :items="itemsSelectOrganizations"
              item-text="state"
              item-value="abbr"
              hint="Nombre de la organización"
              persistent-hint
              small-chips
            ></v-select>
          </v-flex>
          <template v-if="showPermissionIn">
            <v-flex xs6>
              <v-text-field
                :readonly="process != 'new'"
                v-model="authorization_number"
                prepend-inner-icon="fa-bars"
                placeholder="Número de autorización"
                clearable
                disabled
                hint="Numero de autorización"
                persistent-hint
              ></v-text-field>
            </v-flex>
            <v-flex xs6>
              <v-menu
                :readonly="process != 'new'"
                v-model="menu_date_issue"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                disabled
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    :readonly="process != 'new'"
                    v-model="date_issue"
                    label="Fecha de emisión"
                    prepend-icon="event"
                    disabled
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  :readonly="process != 'new'"
                  v-model="date_issue"
                  @input="menu_date_issue = false"
                ></v-date-picker>
              </v-menu>
            </v-flex>
            <v-flex xs6>
              <v-menu
                :readonly="process != 'new'"
                v-model="menu_date_notification"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                disabled
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    :readonly="process != 'new'"
                    v-model="date_notification"
                    label="Fecha de notificación"
                    prepend-icon="event"
                    disabled
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  :readonly="process != 'new'"
                  v-model="date_notification"
                  @input="menu_date_notification = false"
                ></v-date-picker>
              </v-menu>
            </v-flex>
            <v-flex xs6>
              <v-combobox
                :readonly="process != 'new'"
                v-model="select_issued_by"
                :items="items_select_issued_by"
                hide-selected
                item-text="nombre"
                item-value="codigo"
                hint="Si el campo no está en la lista, escríbalo y de click en ENTER"
                label="Expedido por"
                persistent-hint
                return-object
                disabled
                small-chips
              ></v-combobox>
            </v-flex>
            <v-flex xs6>
              <v-menu
                :readonly="process != 'new'"
                v-model="menu_date_effective_authorization_init"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                disabled
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    :readonly="process != 'new'"
                    v-model="date_effective_authorization_init"
                    label="Fecha de inicio del período de autorización"
                    prepend-icon="event"
                    disabled
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  :readonly="process != 'new'"
                  v-model="date_effective_authorization_init"
                  @input="menu_date_effective_authorization_init = false"
                ></v-date-picker>
              </v-menu>
            </v-flex>
            <v-flex xs6>
              <v-menu
                :readonly="process != 'new'"
                v-model="menu_date_effective_authorization_finish"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                disabled
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    :readonly="process != 'new'"
                    v-model="date_effective_authorization_finish"
                    label="Fecha de finalización del período de autorización"
                    prepend-icon="event"
                    disabled
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  :readonly="process != 'new'"
                  v-model="date_effective_authorization_finish"
                  @input="menu_date_effective_authorization_finish = false"
                ></v-date-picker>
              </v-menu>
            </v-flex>
            <v-flex xs6>
              <v-select
                :readonly="process != 'new'"
                v-model="select_fishinng_permission_validity"
                prepend-inner-icon="map"
                hint="Vigencia del permiso de pesca comercial (años)"
                :items="items_select_fishinng_permission_validity"
                item-text="nombre"
                item-value="codigo"
                label="Vigencia del permiso de pesca comercial (años)"
                persistent-hint
                return-object
                single-line
                disabled
              ></v-select>
            </v-flex>
            <v-flex xs6>
              <v-select
                :readonly="process != 'new'"
                v-model="select_authorized_zone"
                prepend-inner-icon="map"
                hint="Zona autorizada"
                :items="items_select_authorized_zone"
                item-text="nombre"
                item-value="codigo"
                label="Zona autorizada"
                persistent-hint
                return-object
                single-line
                disabled
              ></v-select>
            </v-flex>
            <v-flex xs6>
              <v-combobox
                :readonly="process != 'new'"
                v-model="select_authorized_landing_port"
                :items="
                  type == 'ART'
                    ? items_select_authorized_landing_port
                    : items_select_authorized_landing_port_ind
                "
                item-text="nombre"
                item-value="codigo"
                return-object
                single-line
                hint="Si el campo no está en la lista, escríbalo y de click en ENTER"
                label="Puerto de desembarque autorizado en Colombia"
                persistent-hint
                disabled
                small-chips
              ></v-combobox>
            </v-flex>
            <v-flex xs6>
              <v-combobox
                :readonly="process != 'new'"
                v-model="select_types_of_fishing"
                :items="items_select_types_of_fishing"
                item-text="nombre"
                item-value="codigo"
                label="Tipo de pesquería"
                hint="Si el campo no está en la lista, escríbalo y de click en ENTER"
                persistent-hint
                return-object
                single-line
                multiple
                chips
                disabled
              >
                <template v-slot:selection="data">
                  <v-chip
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    @click="data.select"
                    >{{
                      data.item.codigo != "CINCO"
                        ? data.item.nombre
                        : data.item.pivot.description
                    }}</v-chip
                  >
                </template>
              </v-combobox>
            </v-flex>
            <v-flex xs6>
              <v-combobox
                :readonly="process != 'new'"
                v-model="select_fishing_arts"
                :items="items_select_fishing_arts"
                item-text="nombre"
                item-value="codigo"
                label="Artes de pesca"
                hint="Si el campo no está en la lista, escríbalo y de click en ENTER"
                persistent-hint
                return-object
                single-line
                multiple
                chips
                disabled
              >
                <template v-slot:selection="data">
                  <v-chip v-bind="data.attrs" :input-value="data.selected">
                    {{
                      data.item.pivot !== undefined
                        ? data.item.pivot.description
                        : data.item.nombre !== undefined
                        ? data.item.nombre
                        : data.item
                    }}
                  </v-chip>
                </template>
              </v-combobox>
            </v-flex>
            <v-flex xs6>
              <v-select
                :readonly="process != 'new'"
                v-model="select_authorized_arts"
                prepend-inner-icon="map"
                hint="Artes autorizados"
                :items="items_select_authorized_arts"
                item-text="nombre"
                item-value="codigo"
                label="Artes autorizados"
                persistent-hint
                return-object
                single-line
                disabled
                multiple
                :options="options"
                v-on:input="limiter"
              ></v-select>
            </v-flex>
          </template>
          <v-flex xs12 class="mb-4">
            <v-subheader light class="subtitle-1 font-italic font-weight-bold"
              >PESCA ILEGAL</v-subheader
            >
            <v-divider light></v-divider>
          </v-flex>

          <v-flex
            xs12
            class="mb-4"
            v-if="boat.registration_number != undefined"
          >
            <v-subheader light class="subtitle-1 font-italic font-weight-bold">
              INFORMACIÓN DE LA EMBARCACIÓN
              <v-spacer></v-spacer>
            </v-subheader>

            <v-divider light></v-divider>
          </v-flex>

          <v-divider></v-divider>

          <template v-if="boat.registration_number != undefined">
            <v-card width="100%">
              <v-list subheader two-line flat>
                <v-list-item-group>
                  <v-list-item>
                    <template>
                      <v-list-item-content>
                        <v-list-item-title>{{
                          boat.boat_name
                        }}</v-list-item-title>
                        <v-list-item-subtitle>{{
                          boat.registration_number
                        }}</v-list-item-subtitle>
                      </v-list-item-content>

                      <v-list-item-icon>
                        <v-icon>fa-ship</v-icon>
                        <v-icon
                          small
                          title="Editar"
                          @click="
                            dialogBoat = true;
                            el = 1;
                          "
                          >fa-edit</v-icon
                        >
                      </v-list-item-icon>
                    </template>
                  </v-list-item>
                </v-list-item-group>
                <!-- Fin Lista -->
              </v-list>
            </v-card>
          </template>

          <v-row class="mt-4">
            <v-container>
              <v-row>
                <v-col cols="6" sm="12" md="6">
                  <v-select
                    :readonly="process != 'new'"
                    v-model="select_reporting_institution"
                    prepend-inner-icon="map"
                    hint="Institución que reporta"
                    :items="items_select_reporting_institution"
                    item-text="state"
                    item-value="abbr"
                    label="Institución que reporta"
                    persistent-hint
                    return-object
                    single-line
                  ></v-select>
                </v-col>
                <v-col cols="6" sm="12" md="6">
                  <v-menu
                    :readonly="process != 'new'"
                    v-model="menu_date_report"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        :readonly="process != 'new'"
                        v-model="date_report"
                        label="Fecha del Reporte de Pesca Ilegal"
                        prepend-icon="event"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      :readonly="process != 'new'"
                      v-model="date_report"
                      @input="menu_date_report = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-col cols="3" sm="12" md="3">
                      <v-text-field
                        :readonly="process != 'new'"
                        v-model="type_fact_location_latitude"
                        label="Localización del hecho (LATITUD)"
                        @keyup="Valid(type_fact_location_latitude)"
                        v-on="on"
                      ></v-text-field>
                      <v-chip
                        v-if="Valid(type_fact_location_latitude)"
                        class="ma-2"
                        color="green"
                        text-color="white"
                      >
                        Formato Correcto
                      </v-chip>
                      <v-chip
                        v-if="!Valid(type_fact_location_latitude)"
                        class="ma-2"
                        color="red"
                        text-color="white"
                      >
                        Formato Invalido
                      </v-chip>
                    </v-col>
                  </template>
                  <span>EJ: 41°24'12,2"N</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-col cols="3" sm="12" md="3">
                      <v-text-field
                        :readonly="process != 'new'"
                        v-model="type_fact_location_longitude"
                        label="Localización del hecho (LONGITUD)"
                        @keyup="Valid(type_fact_location_longitude)"
                        v-on="on"
                      ></v-text-field>
                      <v-chip
                        v-if="Valid(type_fact_location_longitude)"
                        class="ma-2"
                        color="green"
                        text-color="white"
                      >
                        Formato Correcto
                      </v-chip>
                      <v-chip
                        v-if="!Valid(type_fact_location_longitude)"
                        class="ma-2"
                        color="red"
                        text-color="white"
                      >
                        Formato Invalido
                      </v-chip>
                    </v-col>
                  </template>
                  <span>EJ: 41°24'12,2"N</span>
                </v-tooltip>

                <v-col cols="6" sm="12" md="6">
                  <v-select
                    :readonly="process != 'new'"
                    v-model="select_entity_vessel_boat"
                    prepend-inner-icon="map"
                    hint="Entidad a la que se pone a disposición la embarcación"
                    :items="items_select_entity_vessel_boat"
                    item-text="state"
                    item-value="abbr"
                    label="Entidad a la que se pone a disposición la embarcación"
                    persistent-hint
                    return-object
                    single-line
                  ></v-select>
                </v-col>
                <v-col cols="6" sm="12" md="6">
                  <v-text-field
                    :readonly="process != 'new'"
                    v-model="type_product_seized"
                    label="Tipo de producto incautado"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" sm="12" md="6">
                  <v-text-field
                    :readonly="process != 'new'"
                    v-model="amount_product_seized"
                    prepend-inner-icon
                    placeholder="Cantidad de producto incautado (kg)"
                    hint="Cantidad de producto incautado (kg)"
                    persistent-hint
                    suffix="kg"
                    type="number"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" sm="12" md="6">
                  <v-select
                    :readonly="process != 'new'"
                    v-model="select_entity_product_seized"
                    prepend-inner-icon="map"
                    hint="Entidad a la que se pone a disposición el producto incautado"
                    :items="items_select_entity_product_seized"
                    item-text="state"
                    item-value="abbr"
                    label="Entidad a la que se pone a disposición el producto incautado"
                    persistent-hint
                    return-object
                    single-line
                  ></v-select>
                </v-col>
                <v-col cols="6" sm="12" md="6">
                  <v-select
                    :readonly="process != 'new'"
                    v-model="select_entity_fishing_gear"
                    prepend-inner-icon="map"
                    hint="Entidad a la que se pone a disposición los artes de pesca"
                    :items="items_select_entity_fishing_gear"
                    item-text="state"
                    item-value="abbr"
                    label="Entidad a la que se pone a disposición los artes de pesca"
                    persistent-hint
                    return-object
                    single-line
                  ></v-select>
                </v-col>
                <v-col cols="6" sm="12" md="6">
                  <p>
                    ¿Se aperturó investigación por violación al estatuto
                    pesquero?
                  </p>
                  <v-radio-group
                    :readonly="process != 'new'"
                    v-model="radiosStatuteViolation"
                    row
                  >
                    <v-radio label="Si" value="SI"></v-radio>
                    <v-radio label="No" value="NO"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col
                  cols="3"
                  sm="12"
                  md="3"
                  v-if="radiosStatuteViolation == 'SI'"
                >
                  <v-text-field
                    :readonly="process != 'new'"
                    v-model="nur"
                    prepend-inner-icon
                    placeholder="Número único de registro (NUR)"
                    hint="Número único de registro (NUR)"
                    persistent-hint
                    type="number"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="3"
                  sm="12"
                  md="3"
                  v-if="radiosStatuteViolation == 'SI'"
                >
                  <v-menu
                    :readonly="process != 'new'"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        :readonly="process != 'new'"
                        v-model="illegal_fishing_date"
                        label="Fecha de Emisión"
                        prepend-icon="event"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      :readonly="process != 'new'"
                      v-model="illegal_fishing_date"
                      @input="menu_illegal_fishing_date = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col
                  cols="6"
                  sm="12"
                  md="6"
                  v-if="radiosStatuteViolation == 'SI'"
                >
                  <v-select
                    :readonly="process != 'new'"
                    v-model="select_research_type_person"
                    prepend-inner-icon="map"
                    hint="Tipo de persona que se le apertura la investigación"
                    :items="items_select_research_type_person"
                    item-text="state"
                    item-value="abbr"
                    label="Tipo de persona que se le apertura la investigación"
                    persistent-hint
                    return-object
                    single-line
                  ></v-select>
                </v-col>
                <v-col
                  cols="6"
                  sm="12"
                  md="6"
                  v-if="radiosStatuteViolation == 'SI'"
                >
                  <v-select
                    :readonly="process != 'new'"
                    v-model="select_type_document"
                    prepend-inner-icon="map"
                    hint="Tipo de Documemto"
                    :items="items_select_type_document"
                    item-text="state"
                    item-value="abbr"
                    label="Tipo de Documemto"
                    persistent-hint
                    return-object
                    single-line
                  ></v-select>
                </v-col>
                <v-col
                  cols="6"
                  sm="12"
                  md="6"
                  v-if="radiosStatuteViolation == 'SI'"
                >
                  <v-text-field
                    :readonly="process != 'new'"
                    v-model="number_id"
                    prepend-inner-icon
                    placeholder="Identificación de empresa o persona que se le apertura la investigación"
                    hint="Identificación de empresa o persona que se le apertura la investigación"
                    persistent-hint
                    type="number"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="6"
                  sm="12"
                  md="6"
                  v-if="radiosStatuteViolation == 'SI'"
                >
                  <v-text-field
                    :readonly="process != 'new'"
                    v-model="name_research_person"
                    prepend-inner-icon
                    placeholder="Nombre de empresa o persona que se le apertura la investigación"
                    hint="Nombre de empresa o persona que se le apertura la investigación"
                    persistent-hint
                  ></v-text-field>
                </v-col>

                <v-col
                  cols="6"
                  sm="12"
                  md="6"
                  v-if="radiosStatuteViolation == 'SI'"
                >
                  <v-combobox
                    :readonly="process != 'new'"
                    v-model="select_infringement_process"
                    :items="items_select_infringement_process"
                    item-text="nombre"
                    item-value="codigo"
                    hint="Si el campo no está en la lista, escríbalo y de click en ENTER"
                    label="Infracción en proceso de investigación"
                    persistent-hint
                    return-object
                    single-line
                    multiple
                    chips
                  >
                    <template v-slot:selection="data">
                      <v-chip
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        @click="data.select"
                      >
                        {{
                          data.item.pivot !== undefined
                            ? data.item.pivot.description
                            : data.item.nombre !== undefined
                            ? data.item.nombre
                            : data.item
                        }}
                      </v-chip>
                    </template>
                  </v-combobox>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-textarea
                    :readonly="process != 'new'"
                    v-model="description_or_observation"
                    placeholder="Descripción u observación"
                    hint="Descripción u observación"
                    persistent-hint
                    rows="1"
                    auto-grow
                  ></v-textarea>
                </v-col>

                <v-col
                  cols="6"
                  sm="12"
                  md="6"
                  v-if="radiosStatuteViolation == 'SI'"
                >
                  <p>¿Sanciones al estatuto pesquero?</p>
                  <v-radio-group
                    :readonly="process != 'new'"
                    v-model="radiosPenaltyStatute"
                    row
                  >
                    <v-radio label="Si" value="SI"></v-radio>
                    <v-radio label="No" value="NO"></v-radio>
                  </v-radio-group>
                </v-col>

                <v-col
                  cols="6"
                  sm="12"
                  md="6"
                  v-if="
                    radiosStatuteViolation == 'SI' &&
                    radiosPenaltyStatute == 'SI'
                  "
                >
                  <v-text-field
                    :readonly="process != 'new'"
                    v-model="administrative_act_number"
                    placeholder="Número acto administrativo"
                    hint="Número acto administrativo"
                    persistent-hint
                    type="number"
                  ></v-text-field>
                </v-col>

                <v-col
                  cols="6"
                  sm="12"
                  md="6"
                  v-if="
                    radiosStatuteViolation == 'SI' &&
                    radiosPenaltyStatute == 'SI'
                  "
                >
                  <v-menu
                    :readonly="process != 'new'"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        :readonly="process != 'new'"
                        label="Fecha de Emisión"
                        prepend-icon="event"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      :readonly="process != 'new'"
                      v-model="illegal_fishing_date"
                      @input="menu_illegal_fishing_date = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <v-col
                  cols="6"
                  sm="12"
                  md="6"
                  v-if="
                    radiosStatuteViolation == 'SI' &&
                    radiosPenaltyStatute == 'SI'
                  "
                >
                  <v-combobox
                    :readonly="process != 'new'"
                    v-model="select_penalty_infringement"
                    prepend-inner-icon="map"
                    hint="Tipo de infracción sancionada"
                    :items="items_select_infringement_process"
                    item-text="nombre"
                    item-value="codigo"
                    label="Tipo de infracción sancionada"
                    persistent-hint
                    return-object
                    single-line
                    multiple
                    chips
                  >
                    <template v-slot:selection="data">
                      <v-chip
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        @click="data.select"
                      >
                        {{
                          data.item.pivot !== undefined
                            ? data.item.pivot.description
                            : data.item.nombre !== undefined
                            ? data.item.nombre
                            : data.item
                        }}
                      </v-chip>
                    </template>
                  </v-combobox>
                </v-col>

                <v-col
                  cols="6"
                  sm="12"
                  md="6"
                  v-if="
                    radiosStatuteViolation == 'SI' &&
                    radiosPenaltyStatute == 'SI'
                  "
                >
                  <v-combobox
                    :readonly="process != 'new'"
                    v-model="select_penalty_infringement_type"
                    prepend-inner-icon="map"
                    hint="Tipo de sanción"
                    :items="items_select_penalty_infringement_type"
                    item-text="nombre"
                    item-value="codigo"
                    label="Tipo de sanción"
                    persistent-hint
                    return-object
                    single-line
                    multiple
                    chips
                  >
                    <template v-slot:selection="data">
                      <v-chip
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        @click="data.select"
                      >
                        {{
                          data.item.pivot !== undefined
                            ? data.item.pivot.description
                            : data.item.nombre !== undefined
                            ? data.item.nombre
                            : data.item
                        }}
                      </v-chip>
                    </template>
                  </v-combobox>
                </v-col>
                <v-col
                  v-if="
                    radiosStatuteViolation == 'SI' &&
                    radiosPenaltyStatute == 'SI' &&
                    select_penalty_infringement_type.find(
                      (x) => x.codigo == 'DOS'
                    ) != undefined
                  "
                  cols="6"
                  sm="12"
                  md="6"
                >
                  <v-text-field
                    :readonly="process != 'new'"
                    v-model="fine_value"
                    prepend-inner-icon="attach_money"
                    placeholder="Valor de la multa"
                    hint="Valor de la multa"
                    persistent-hint
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="12"></v-col>
                <v-col
                  cols="2"
                  sm="12"
                  md="2"
                  v-if="
                    radiosStatuteViolation == 'SI' &&
                    radiosPenaltyStatute == 'SI'
                  "
                >
                  <v-checkbox
                    :disabled="process == 'resolv'"
                    :readonly="process != 'new'"
                    v-model="reposicion"
                    label="Recurso de Reposición"
                    hide-details
                    class="shrink mr-2 mt-0"
                    value="SI"
                  ></v-checkbox>
                </v-col>
                <v-col cols="10" sm="12" md="10" v-if="reposicion == 'SI'">
                  <v-textarea
                    :disabled="process == 'resolv'"
                    :readonly="process != 'new'"
                    v-model="description_reposicion"
                    placeholder="Observación del Recurso"
                    hint="Observación del Recurso"
                    persistent-hint
                    rows="1"
                    auto-grow
                  ></v-textarea>
                </v-col>
                <v-col cols="12" sm="12" md="12"></v-col>
                <v-col
                  cols="2"
                  sm="12"
                  md="2"
                  v-if="
                    radiosStatuteViolation == 'SI' &&
                    radiosPenaltyStatute == 'SI'
                  "
                >
                  <v-checkbox
                    :disabled="process == 'resolv'"
                    :readonly="process != 'new'"
                    v-model="ejecutoriado"
                    label="Fallo Ejecutoriado"
                    hide-details
                    class="shrink mr-2 mt-0"
                    value="SI"
                  ></v-checkbox>
                </v-col>
                <v-col cols="10" sm="12" md="10" v-if="ejecutoriado == 'SI'">
                  <v-textarea
                    :disabled="process == 'resolv'"
                    :readonly="process != 'new'"
                    v-model="description_ejecutoriado"
                    placeholder="Observación del Fallo Ejecutoriado"
                    hint="Observación del Fallo Ejecutoriado"
                    persistent-hint
                    rows="1"
                    auto-grow
                  ></v-textarea>
                </v-col>
                <v-col cols="12" sm="12" md="12"></v-col>
                <v-col cols="4" sm="6" md="4" v-if="process == 'resolv'">
                  <v-select
                    v-model="select_sanction_state"
                    prepend-inner-icon="map"
                    hint="Estado de la sanción"
                    :items="items_select_sanction_state"
                    item-text="state"
                    item-value="abbr"
                    label="Estado de la sanción"
                    persistent-hint
                    return-object
                    single-line
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-row>

          <v-alert
            class="mt-3"
            width="100%"
            type="error"
            dismissible
            v-if="showStatuteViolationAlert"
            >{{ this.messageStatuteViolationArt }}.</v-alert
          >
          <v-alert
            width="100%"
            class="mt-3"
            type="success"
            dismissible
            v-if="saveStatuteViolationAlert"
            >El registro de investigación por violación al estatuto pesquero ha
            sido guardado.</v-alert
          >
        </v-layout>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          v-if="
            process == 'new' &&
            radiosPenaltyStatute != 'NO' &&
            radiosStatuteViolation != 'NO'
          "
          @click="saveStatuteViolation('save')"
          class="indigo darken-4 white--text"
          large
          >Guardar</v-btn
        >
        <v-btn
          v-if="
            process == 'resolv' ||
            (this.process == 'new' &&
              (radiosPenaltyStatute == 'NO' || radiosStatuteViolation == 'NO'))
          "
          @click="saveStatuteViolation('close')"
          class="indigo darken-4 white--text"
          large
          >Cerrar Investigación</v-btn
        >
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { VMoney } from "v-money";
import { required } from "vuelidate/lib/validators";
import {
  saveCompanyInvUrl,
  saveFishermenInvUrl,
  saveStatuteViolationUrl,
  saveBoatViolationStatuteArtUrl,
  getHeader,
} from "./../config.js";
import swal from "sweetalert";
import { consultar } from "../services/variablesconcepto";
export default {
  props: ["id", "process"],
  data: () => ({
    boats: [],
    reposicion: "NO",
    ejecutoriado: "NO",
    description_reposicion: "",
    description_ejecutoriado: "",
    messageStatuteViolationArt: "",
    type_product_seized: "",
    amount_product_seized: 0,
    type_fact_location_longitude: "",
    type_fact_location_latitude: "",
    authorization_number_modal: "",
    consecutive: "",
    location: "",
    name: "",
    nit: "",
    nit_modal: "",
    name_modal: "",
    search_nit: "",
    external_marking: "",
    legal_representative: "",
    identification_number: "",
    legal_representative_modal: "",
    identification_number_modal: "",
    identification_number_show: "",
    address_modal: "",
    address_show: "",
    phone_modal: "",
    phone: "",
    phone_show: "",
    email_modal: "",
    email_show: "",
    address: "",
    selectedBoat: "",
    authorization_number: "",
    place_issue_registration: "",
    nur: "",
    name_research_person: "",
    options: "",
    vessel_name: "",
    sell_products_to: "",
    components_fad: "",
    cheating_amount: "",
    hook_size_type: "",
    hook_quantity: "",
    mother_line_length: "",
    administrative_act_number: "",

    description_or_observation: "",
    alertPermissionNotFound: true,
    showStatuteViolationAlert: false,
    saveStatuteViolationAlert: false,
    menu1: false,
    menu2: false,
    menu3: false,
    menu4: false,
    menu7: false,
    menu8: false,
    menu9: false,
    dialog: false,
    dialogBoat: false,
    showBoatsIn: false,
    showPermissionIn: false,
    dialogNewCompany: false,
    menu_date_emition: false,
    menu_sanction_date: false,
    dialogPermisionario: false,
    dialogNewPermission: false,
    alertNotFoundBoatsIn: false,
    alertNotFoundCompany: false,
    menu_date_issue: false,
    menu_date_report: false,
    showStatuteViolationteAlert: false,
    alertNotFoundPermissionArt: false,
    menu_illegal_fishing_date: false,
    showStatuteViolationAlertIn: false,
    menu_date_notification: false,
    menu_date_issue_registration: false,
    menu_date_expiration_registration: false,
    menu_date_effective_authorization_init: false,
    menu_date_effective_authorization_finish: false,
    date_filing: new Date().toISOString().substr(0, 10),
    date_emition: new Date().toISOString().substr(0, 10),
    sanction_date: new Date().toISOString().substr(0, 10),
    date_end_period: new Date().toISOString().substr(0, 10),
    date_assignment: new Date().toISOString().substr(0, 10),
    date_issue: new Date().toISOString().substr(0, 10),
    date_report: new Date().toISOString().substr(0, 10),
    date_start_period: new Date().toISOString().substr(0, 10),
    illegal_fishing_date: new Date().toISOString().substr(0, 10),
    date_notification: new Date().toISOString().substr(0, 10),
    date_issue_registration: new Date().toISOString().substr(0, 10),
    date_expiration_registration: new Date().toISOString().substr(0, 10),
    date_effective_authorization_init: new Date().toISOString().substr(0, 10),
    date_effective_authorization_finish: new Date().toISOString().substr(0, 10),
    radios: "SI",
    type: "",
    radios1: "SI",
    radios2: "SI",
    radios4: "SI",
    radios5: "SI",
    radios6: "SI",
    radios7: "SI",
    radios8: "SI",
    radios9: "SI",
    radiosGps: "SI",
    radiosLoran: "SI",
    radiosRadar: "SI",
    radiosChart: "SI",
    radiosRadios: "SI",
    radiosEcosonda: "SI",
    radiosMotoNave: "SI",
    radiosFishinPermit: "SI",
    radiosCurrentFishingPermit: "SI",
    radiosPenaltyStatute: "",
    radiosMagneticCompass: "SI",
    radiosStatuteViolation: "",
    radiosFishAggregatingDevice: "SI",
    e1: 1,
    value_vessel: 0,
    eslora_length: 0,
    extreme_breadth: 0,
    registration_tonnage: 0,
    registration_tonnage_gross: 0,
    main_motor_power: 0,
    number_wineries: 0,
    tall_wineries: 0,
    long_wineries: 0,
    width_wineries: 0,
    fishing_zone: "",
    capacity_wineries: 0,
    crew_number: 0,
    fishermen_number: 0,
    fishing_value: 0,
    total_number_lines: 0,
    number_artifacts: 0,
    quantity_fad: 0,

    fine_value: 0,

    items_location: [
      { state: "1. San Andrés", abbr: "UNO" },
      { state: "2. Providencia y Santa Catalina", abbr: "DOS" },
    ],
    select_organization: { state: "", abbr: "" },
    select_organization_show: null,
    organization_cooperative: "",
    organization_cooperative_show: "",
    email: "",
    select_nationality: { state: "Colombia", abbr: "COL" },
    select_nationality_show: { state: "Colombia", abbr: "COL" },
    items_select_nationality: [
      { state: "Afghanistan", abbr: " AFG" },
      { state: "Albania", abbr: " ALB" },
      { state: "Algeria", abbr: " DZA" },
      { state: "American Samoa", abbr: " ASM" },
      { state: "Andorra", abbr: " AND" },
      { state: "Angola", abbr: " AGO" },
      { state: "Anguilla", abbr: " AIA" },
      { state: "Antarctica", abbr: " ATA" },
      { state: "Antigua and Barbuda", abbr: " ATG" },
      { state: "Argentina", abbr: " ARG" },
      { state: "Armenia", abbr: " ARM" },
      { state: "Aruba", abbr: " ABW" },
      { state: "Australia", abbr: " AUS" },
      { state: "Austria", abbr: " AUT" },
      { state: "Azerbaijan", abbr: " AZE" },
      { state: "Bahamas", abbr: " BHS" },
      { state: "Bahrain", abbr: " BHR" },
      { state: "Bangladesh", abbr: " BGD" },
      { state: "Barbados", abbr: " BRB" },
      { state: "Belarus", abbr: " BLR" },
      { state: "Belgium", abbr: " BEL" },
      { state: "Belize", abbr: " BLZ" },
      { state: "Benin", abbr: " BEN" },
      { state: "Bermuda", abbr: " BMU" },
      { state: "Bhutan", abbr: " BTN" },
      { state: "Bolivia", abbr: " BO" },
      { state: "Bolivia", abbr: " BOL" },
      { state: "Bosnia and Herzegovina", abbr: " BIH" },
      { state: "Botswana", abbr: " BWA" },
      { state: "Bouvet Island", abbr: " BVT" },
      { state: "Brazil", abbr: " BRA" },
      { state: "British Indian Ocean Territory", abbr: " IOT" },
      { state: "Brunei Darussalam", abbr: " BRN" },
      { state: "Brunei", abbr: " BRN" },
      { state: "Bulgaria", abbr: " BGR" },
      { state: "Burkina Faso", abbr: " BFA" },
      { state: "Burundi", abbr: " BDI" },
      { state: "Cambodia", abbr: " KHM" },
      { state: "Cameroon", abbr: " CMR" },
      { state: "Canada", abbr: " CAN" },
      { state: "Cape Verde", abbr: " CPV" },
      { state: "Cayman Islands", abbr: " CYM" },
      { state: "Central African Republic", abbr: " CAF" },
      { state: "Chad", abbr: " TCD" },
      { state: "Chile", abbr: " CHL" },
      { state: "China", abbr: " CHN" },
      { state: "Christmas Island", abbr: " CXR" },
      { state: "Cocos (Keeling) Islands", abbr: " CCK" },
      { state: "Colombia", abbr: "COL" },
      { state: "Comoros", abbr: " COM" },
      { state: "Congo", abbr: " COG" },
      { state: "Congo", abbr: " CD" },
      { state: "Cook Islands", abbr: " COK" },
      { state: "Costa Rica", abbr: " CRI" },
      { state: "CÃ´te d'Ivoire", abbr: " CIV" },
      { state: "Ivory Coast", abbr: " CIV" },
      { state: "Croatia", abbr: " HRV" },
      { state: "Cuba", abbr: " CUB" },
      { state: "Cyprus", abbr: " CYP" },
      { state: "Czech Republic", abbr: " CZE" },
      { state: "Denmark", abbr: " DNK" },
      { state: "Djibouti", abbr: " DJI" },
      { state: "Dominica", abbr: " DMA" },
      { state: "Dominican Republic", abbr: " DOM" },
      { state: "Ecuador", abbr: " ECU" },
      { state: "Egypt", abbr: " EGY" },
      { state: "El Salvador", abbr: " SLV" },
      { state: "Equatorial Guinea", abbr: " GNQ" },
      { state: "Eritrea", abbr: " ERI" },
      { state: "Estonia", abbr: " EST" },
      { state: "Ethiopia", abbr: " ETH" },
      { state: "Falkland Islands (Malvinas)", abbr: " FLK" },
      { state: "Faroe Islands", abbr: " FRO" },
      { state: "Fiji", abbr: " FJI" },
      { state: "Finland", abbr: " FIN" },
      { state: "France", abbr: " FRA" },
      { state: "French Guiana", abbr: " GUF" },
      { state: "French Polynesia", abbr: " PYF" },
      { state: "French Southern Territories", abbr: " ATF" },
      { state: "Gabon", abbr: " GAB" },
      { state: "Gambia", abbr: " GMB" },
      { state: "Georgia", abbr: " GEO" },
      { state: "Germany", abbr: " DEU" },
      { state: "Ghana", abbr: " GHA" },
      { state: "Gibraltar", abbr: " GIB" },
      { state: "Greece", abbr: " GRC" },
      { state: "Greenland", abbr: " GRL" },
      { state: "Grenada", abbr: " GRD" },
      { state: "Guadeloupe", abbr: " GLP" },
      { state: "Guam", abbr: " GUM" },
      { state: "Guatemala", abbr: " GTM" },
      { state: "Guernsey", abbr: " GGY" },
      { state: "Guinea", abbr: " GIN" },
      { state: "Guinea-Bissau", abbr: " GNB" },
      { state: "Guyana", abbr: " GUY" },
      { state: "Haiti", abbr: " HTI" },
      { state: "Heard Island and McDonald Islands", abbr: " HMD" },
      { state: "Holy See (Vatican City State)", abbr: " VAT" },
      { state: "Honduras", abbr: " HND" },
      { state: "Hong Kong", abbr: " HKG" },
      { state: "Hungary", abbr: " HUN" },
      { state: "Iceland", abbr: " ISL" },
      { state: "India", abbr: " IND" },
      { state: "Indonesia", abbr: " IDN" },
      { state: "Iran", abbr: " IR" },
      { state: "Iraq", abbr: " IRQ" },
      { state: "Ireland", abbr: " IRL" },
      { state: "Isle of Man", abbr: " IMN" },
      { state: "Israel", abbr: " ISR" },
      { state: "Italy", abbr: " ITA" },
      { state: "Jamaica", abbr: " JAM" },
      { state: "Japan", abbr: " JPN" },
      { state: "Jersey", abbr: " JEY" },
      { state: "Jordan", abbr: " JOR" },
      { state: "Kazakhstan", abbr: " KAZ" },
      { state: "Kenya", abbr: " KEN" },
      { state: "Kiribati", abbr: " KIR" },
      { state: "Korea", abbr: " KP" },
      { state: "Korea", abbr: " KR" },
      { state: "South Korea", abbr: " KOR" },
      { state: "Kuwait", abbr: " KWT" },
      { state: "Kyrgyzstan", abbr: " KGZ" },
      { state: "Lao People's Democratic Republic", abbr: " LAO" },
      { state: "Latvia", abbr: " LVA" },
      { state: "Lebanon", abbr: " LBN" },
      { state: "Lesotho", abbr: " LSO" },
      { state: "Liberia", abbr: " LBR" },
      { state: "Libyan Arab Jamahiriya", abbr: " LBY" },
      { state: "Libya", abbr: " LBY" },
      { state: "Liechtenstein", abbr: " LIE" },
      { state: "Lithuania", abbr: " LTU" },
      { state: "Luxembourg", abbr: " LUX" },
      { state: "Macao", abbr: " MAC" },
      { state: "Macedonia", abbr: " MK" },
      { state: "Madagascar", abbr: " MDG" },
      { state: "Malawi", abbr: " MWI" },
      { state: "Malaysia", abbr: " MYS" },
      { state: "Maldives", abbr: " MDV" },
      { state: "Mali", abbr: " MLI" },
      { state: "Malta", abbr: " MLT" },
      { state: "Marshall Islands", abbr: " MHL" },
      { state: "Martinique", abbr: " MTQ" },
      { state: "Mauritania", abbr: " MRT" },
      { state: "Mauritius", abbr: " MUS" },
      { state: "Mayotte", abbr: " MYT" },
      { state: "Mexico", abbr: " MEX" },
      { state: "Micronesia", abbr: " FM" },
      { state: "Moldova", abbr: " MD" },
      { state: "Monaco", abbr: " MCO" },
      { state: "Mongolia", abbr: " MNG" },
      { state: "Montenegro", abbr: " MNE" },
      { state: "Montserrat", abbr: " MSR" },
      { state: "Morocco", abbr: " MAR" },
      { state: "Mozambique", abbr: " MOZ" },
      { state: "Myanmar", abbr: " MMR" },
      { state: "Burma", abbr: " MMR" },
      { state: "Namibia", abbr: " NAM" },
      { state: "Nauru", abbr: " NRU" },
      { state: "Nepal", abbr: " NPL" },
      { state: "Netherlands", abbr: " NLD" },
      { state: "Netherlands Antilles", abbr: " ANT" },
      { state: "New Caledonia", abbr: " NCL" },
      { state: "New Zealand", abbr: " NZL" },
      { state: "Nicaragua", abbr: " NIC" },
      { state: "Niger", abbr: " NER" },
      { state: "Nigeria", abbr: " NGA" },
      { state: "Niue", abbr: " NIU" },
      { state: "Norfolk Island", abbr: " NFK" },
      { state: "Northern Mariana Islands", abbr: " MNP" },
      { state: "Norway", abbr: " NOR" },
      { state: "Oman", abbr: " OMN" },
      { state: "Pakistan", abbr: " PAK" },
      { state: "Palau", abbr: " PLW" },
      { state: "Palestinian Territory", abbr: " PS" },
      { state: "Panama", abbr: " PAN" },
      { state: "Papua New Guinea", abbr: " PNG" },
      { state: "Paraguay", abbr: " PRY" },
      { state: "Peru", abbr: " PER" },
      { state: "Philippines", abbr: " PHL" },
      { state: "Pitcairn", abbr: " PCN" },
      { state: "Poland", abbr: " POL" },
      { state: "Portugal", abbr: " PRT" },
      { state: "Puerto Rico", abbr: " PRI" },
      { state: "Qatar", abbr: " QAT" },
      { state: "RÃ©union", abbr: " REU" },
      { state: "Romania", abbr: " ROU" },
      { state: "Russian Federation", abbr: " RUS" },
      { state: "Russia", abbr: " RUS" },
      { state: "Rwanda", abbr: " RWA" },
      { state: "Saint Helena", abbr: " SH" },
      { state: "Saint Kitts and Nevis", abbr: " KNA" },
      { state: "Saint Lucia", abbr: " LCA" },
      { state: "Saint Pierre and Miquelon", abbr: " SPM" },
      { state: "Saint Vincent and the Grenadines", abbr: " VCT" },
      { state: "Saint Vincent & the Grenadines", abbr: " VCT" },
      { state: "St. Vincent and the Grenadines", abbr: " VCT" },
      { state: "Samoa", abbr: " WSM" },
      { state: "San Marino", abbr: " SMR" },
      { state: "Sao Tome and Principe", abbr: " STP" },
      { state: "Saudi Arabia", abbr: " SAU" },
      { state: "Senegal", abbr: " SEN" },
      { state: "Serbia", abbr: " SRB" },
      { state: "Seychelles", abbr: " SYC" },
      { state: "Sierra Leone", abbr: " SLE" },
      { state: "Singapore", abbr: " SGP" },
      { state: "Slovakia", abbr: " SVK" },
      { state: "Slovenia", abbr: " SVN" },
      { state: "Solomon Islands", abbr: " SLB" },
      { state: "Somalia", abbr: " SOM" },
      { state: "South Africa", abbr: " ZAF" },
      { state: "South Georgia and the South Sandwich Islands", abbr: " SGS" },
      { state: "Spain", abbr: " ESP" },
      { state: "Sri Lanka", abbr: " LKA" },
      { state: "Sudan", abbr: " SDN" },
      { state: "Suriname", abbr: " SUR" },
      { state: "Svalbard and Jan Mayen", abbr: " SJM" },
      { state: "Swaziland", abbr: " SWZ" },
      { state: "Sweden", abbr: " SWE" },
      { state: "Switzerland", abbr: " CHE" },
      { state: "Syrian Arab Republic", abbr: " SYR" },
      { state: "Taiwan", abbr: " TW" },
      { state: "Taiwan", abbr: " TWN" },
      { state: "Tajikistan", abbr: " TJK" },
      { state: "Tanzania", abbr: " TZ" },
      { state: "Thailand", abbr: " THA" },
      { state: "Timor-Leste", abbr: " TLS" },
      { state: "Togo", abbr: " TGO" },
      { state: "Tokelau", abbr: " TKL" },
      { state: "Tonga", abbr: " TON" },
      { state: "Trinidad and Tobago", abbr: " TTO" },
      { state: "Trinidad & Tobago", abbr: " TTO" },
      { state: "Tunisia", abbr: " TUN" },
      { state: "Turkey", abbr: " TUR" },
      { state: "Turkmenistan", abbr: " TKM" },
      { state: "Turks and Caicos Islands", abbr: " TCA" },
      { state: "Tuvalu", abbr: " TUV" },
      { state: "Uganda", abbr: " UGA" },
      { state: "Ukraine", abbr: " UKR" },
      { state: "United Arab Emirates", abbr: " ARE" },
      { state: "United Kingdom", abbr: " GBR" },
      { state: "United States", abbr: " USA" },
      { state: "United States Minor Outlying Islands", abbr: " UMI" },
      { state: "Uruguay", abbr: " URY" },
      { state: "Uzbekistan", abbr: " UZB" },
      { state: "Vanuatu", abbr: " VUT" },
      { state: "Venezuela", abbr: " VE" },
      { state: "Venezuela", abbr: " VEN" },
      { state: "Viet Nam", abbr: " VNM" },
      { state: "Vietnam", abbr: " VNM" },
      { state: "Virgin Islands", abbr: " VG" },
      { state: "Virgin Islands", abbr: " VI" },
      { state: "Wallis and Futuna", abbr: " WLF" },
      { state: "Western Sahara", abbr: " ESH" },
      { state: "Yemen", abbr: " YEM" },
      { state: "Zambia", abbr: " ZMB" },
      { state: "Zimbabwe", abbr: " ZWE" },
    ],
    occre: "",
    occre_show: "",
    no_occre: "",
    no_occre_show: "",

    select_penalty_infringement_type: [],
    items_select_penalty_infringement_type: [
      { nombre: "1. Conminación por escrito", codigo: "UNO" },
      { nombre: "2. Multa", codigo: "DOS" },
      {
        nombre:
          "3. Suspensión temporal del permiso, Autorización, concesión o patente, según sea el caso",
        codigo: "TRES",
      },
      {
        nombre:
          "4. Revocatoria del permiso, Autorización, concesión o patente, según sea el caso",
        codigo: "CUATRO",
      },
      {
        nombre: "5. Decomiso de embarcaciones, equipos o productos",
        codigo: "CINCO",
      },
      {
        nombre: "6. Cierre temporal o clausura definitiva del establecimiento",
        codigo: "SEIS",
      },
      // { state: "7. Otro, Cual?", abbr: "SIETE" }
    ],
    select_failure_executed: { state: "", abbr: "" },
    items_select_failure_executed: [
      { state: "1. EJECUTORIADO", abbr: "UNO" },
      { state: "2. RECURSO DE REPOSICIÓN", abbr: "DOS" },
    ],
    select_sanction_state: { state: "", abbr: "" },
    items_select_sanction_state: [
      { state: "1. CUMPLIDA", abbr: "UNO" },
      { state: "2. INCUMPLIDA", abbr: "DOS" },
    ],
    select_penalty_infringement: [],
    select_infringement_process: [],
    items_select_infringement_process: [
      { nombre: "1. Pesca sin permiso o sin patente de pesca", codigo: "UNO" },
      { nombre: "2. Sobrepasar la cuota de pesca", codigo: "DOS" },
      {
        nombre:
          "3. Pesca Comercial Industrial en Zonas de Reserva de Pesca Artesanal",
        codigo: "TRES",
      },
      {
        nombre:
          "4. Pesca Comercial en Área Protegida – Extraer recursos de áreas reservadas",
        codigo: "CUATRO",
      },
      { nombre: "5. Usar artes de pesca no autorizados", codigo: "CINCO" },
      { nombre: "6. Descartes de Fauna Acompañante", codigo: "SEIS" },
      {
        nombre: "7. Pesca o comercialización de especies sin TMC",
        codigo: "SIETE",
      },
      { nombre: "8. Transbordos ", codigo: "OCHO" },
      {
        nombre:
          "9. Incumplimiento de la Zonificación de la Reserva de Biosfera ",
        codigo: "NUEVE",
      },
      { nombre: "10. Pesca durante Veda", codigo: "DIEZ" },
      {
        nombre: "11. Captura dirigida a pez vela, marlín, pez espada",
        codigo: "ONCE",
      },
      { nombre: "12. Aleteo de tiburón", codigo: "DOCE" },
      { nombre: "13. Pesca dirigida a tiburones", codigo: "TRECE" },
      {
        nombre:
          "14. Pesca de Recursos Hidrobiológicos (tortuga, pez loro, otro).",
        codigo: "CATORCE",
      },
      { nombre: "16. Riesgo ambiental", abbr: "DIECISEIS" },
      { nombre: "17. Violación de Frontera", codigo: "DIECISIETE" },
      { nombre: "18. Otras, ¿cuál?", codigo: "DIECIOCHO" },
    ],
    select_type_document: { state: "CC - Cédula de ciudadanía", abbr: "CC" },
    select_type_document_show: {
      state: "CC - Cédula de ciudadanía",
      abbr: "CC",
    },
    items_select_type_document: [
      { state: "CC - Cédula de ciudadanía", abbr: "CC" },
      { state: "CE - Cédula de Extranjeria", abbr: "CE" },
    ],
    number_id: "",
    lastname: "",
    lastname_show: "",
    name_show: "",
    select_type_person: { state: "", abbr: "" },
    select_type_person_show: { state: "", abbr: "" },
    select_research_type_person: { state: "", abbr: "" },
    items_select_research_type_person: [
      { state: "1. NATURAL", abbr: "UNO" },
      { state: "2. JURÍDICA", abbr: "DOS" },
    ],
    select_entity_vessel_boat: { state: "", abbr: "" },
    items_select_entity_vessel_boat: [
      { state: "1. Secretaria de Agricultura y pesca", abbr: "UNO" },
      { state: "2. DIAN", abbr: "DOS" },
      /*{ state: "3. OTRO, CUAL?", abbr: "TRES" }*/
    ],
    select_entity_fishing_gear: { state: "", abbr: "" },
    items_select_entity_fishing_gear: [
      { state: "1. Secretaria de Agricultura y pesca", abbr: "UNO" },
      { state: "2. Fiscalía", abbr: "DOS" },
      { state: "3. MAC BIG LAGOON - PNN", abbr: "TRES" },
      { state: "4. CORALINA", abbr: "CUATRO" },
      /*{ state: "5. OTRA, CUAL?", abbr: "CINCO" }*/
    ],
    select_entity_product_seized: { state: "", abbr: "" },
    items_select_entity_product_seized: [
      { state: "1. Secretaria de Agricultura y pesca", abbr: "UNO" },
      { state: "2. MAC BIG LAGOON - PNN", abbr: "DOS" },
      { state: "3. CORALINA", abbr: "TRES" },
      /*{ state: "4. OTRA, CUAL?", abbr: "CUATRO" }*/
    ],
    select_reporting_institution: { state: "", abbr: "" },
    items_select_reporting_institution: [
      { state: "1. CESYP - ARC", abbr: "UNO" },
      { state: "2. ESTACION GUARDACOSTAS San Andrés - ARC", abbr: "DOS" },
      { state: "3. ESTACION GUARDACOSTAS PROVIDENCIA - ARC", abbr: "TRES" },
      { state: "4. MAC BIG LAGOON - PNN", abbr: "CUATRO" },
      { state: "5. CORALINA", abbr: "CINCO" },
      { state: "6. POLICIA NACIONAL", abbr: "SEIS" },
      /*{ state: "7. OTRA, CUAL?", abbr: "SIETE" }*/
    ],
    select_issued_by: {},
    items_select_issued_by: [
      { nombre: "1. GOBERNACIÓN SAI", codigo: "UNO" },
      { nombre: "2. AUNAP", codigo: "DOS" },
      /*{ state: "3. OTRO, CUAL?", abbr: "TRES" }*/
    ],

    select_type_identification: null,
    items_select_type_identification: [
      { state: "1. CC", abbr: "CC" },
      { state: "2. CE", abbr: "CE" },
    ],
    select_marketing_authorization: { nombre: "", codigo: "" },
    items_select_marketing_authorization: [
      { nombre: "0. EMPRESA", codigo: "CERO" },
      { nombre: "1. Buque (S)", codigo: "BUQUE" },
      { nombre: "2. Propietario del buque (S)", codigo: "DOS" },
      { nombre: "3. Operador / Gerente de Buques", codigo: "TRES" },
      { nombre: "4. Capitán del barco", codigo: "CUATRO" },
    ],

    select_type_of_company: { state: "Natural", abbr: "UNO" },
    items_select_type_of_company: [
      { state: "1.  NATURAL", abbr: "UNO" },
      { state: "2. JURÍDICA", abbr: "DOS" },
    ],
    select_id_type_modal: null,
    items_select_id_type_modal: [
      { state: "Cédula de ciudadanía", abbr: "CC" },
      { state: "Cédula de extranjería", abbr: "CE" },
    ],
    select_type_company_modal: { state: "Natural", abbr: "UNO" },
    items_select_type_company_modal: [
      { state: "Natural", abbr: "UNO" },
      { state: "Jurídica", abbr: "DOS" },
    ],
    select_type_of_request: { state: "", abbr: "" },
    items_select_type_of_request: [
      { state: "1. Nuevo Permiso", abbr: "UNO" },
      { state: "2. Prorroga", abbr: "DOS" },
      { state: "3. Vinculación", abbr: "TRES" },
      { state: "4. Desvinculación", abbr: "CUATRO" },
      { state: "5. Reemplazo motonave", abbr: "CINCO" },
      { state: "6. Cancelación del Permiso", abbr: "SEIS" },
      {
        state: "7. Patente de Pesca (solo en el caso de inspeccion)",
        abbr: "SIETE",
      },
      {
        state: "98. Otro, Cual?",
        abbr: "NOVENTAYOCHO",
      },
    ],
    select: { state: "", abbr: "" },
    items_select: [
      { state: "GOBERNACIÓN SAI", abbr: "GO" },
      { state: "AUNAP", abbr: "AU" },
      { state: "OTRO, CUAL?", abbr: "OT" },
    ],
    select_titular: { state: "", abbr: "" },
    items_select_titular: [
      { state: "EMPRESA", abbr: "EM" },
      { state: "BUQUE(S)", abbr: "BU" },
      { state: "PROPIETARIO DEL BUQUE (S)", abbr: "PB" },
      { state: "OPERADOR / GERENTE DE BUQUES", abbr: "OG" },
      { state: "CAPITÁN DEL BARCO", abbr: "CB" },
    ],
    select_authorized_zone: { nombre: "", codigo: "" },
    items_select_authorized_zone: [
      { nombre: "31. Atlantic, Western Central", codigo: "UNO" },
      { nombre: "77. Pacific, Eastern Central", codigo: "DOS" },
      { nombre: "87. Pacific, Southeast ", codigo: "TRES" },
      { nombre: "98. Other – Otro", codigo: "CUATRO" },
      { nombre: "99. Unknown - Desconocido", codigo: "CINCO" },
      {
        nombre:
          "FAO Fishing Areas at Major Fishing Area, Subarea, Division or Subdivision level10, as documented here: http://www.fao.org/fishery/cwp/handbook/h/en",
        codigo: "FA",
      },
    ],

    select_types_of_fishing: null,
    items_select_types_of_fishing: [
      { nombre: "1. Langosta", codigo: "UNO" },
      { nombre: "2. Pesca blanca", codigo: "DOS" },
      { nombre: "3. Caracol", codigo: "TRES" },
      { nombre: "4. Multiespecífica", codigo: "CUATRO" },
      /*{ nombre: "5. Otro, Cual?", codigo: "CINCO" }*/
    ],

    select_registration_port: { state: "", abbr: "" },
    items_select_registration_port: [
      { state: "CP01 BUENAVENTURA", abbr: "CP01" },
      { state: "CP02 TUMACO", abbr: "CP02" },
      { state: "CP03 BARRANQUILLA", abbr: "CP03" },
      { state: "CP04 SANTA MARTA", abbr: "CP04" },
      { state: "CP05 CARTAGENA", abbr: "CP05" },
      { state: "CP06 RIOHACHA", abbr: "CP06" },
      { state: "CP07 San Andrés", abbr: "CP07" },
      { state: "CPO8 TURBO", abbr: "CPO8" },
      { state: "CP09 COVEÑAS", abbr: "CP09" },
      { state: "CP10 BAHIA SOLANO", abbr: "CP10" },
      { state: "CP11 GUAPI", abbr: "CP11" },
      { state: "CP12 PROVIDENCIA", abbr: "CP12" },
      { state: "CP14 PUERTO BOLIVAR", abbr: "CP14" },
      { state: "CP15 PUERTO CARREÑO", abbr: "CP15" },
      { state: "CP16 LETICIA", abbr: "CP16" },
      { state: "CP17 PUERTO INIRIDA", abbr: "CP17" },
      { state: "CP19 PUERTO LEGUIZAMO", abbr: "CP19" },
      { state: "98. OTRO, CUAL?", abbr: "98" },
    ],
    select_power_unit: { state: "", abbr: "" },
    items_select_power_unit: [
      { state: "KW. Kilowatt", abbr: "KW" },
      { state: "HP. Horse Power", abbr: "HP" },
      { state: "OT. Other", abbr: "OT" },
    ],
    select_helmet_material: { state: "", abbr: "" },
    items_select_helmet_material: [
      { state: "1. Wood - Madera", abbr: "1" },
      { state: "2. Marine Plywood – Madera forrada", abbr: "2" },
      { state: "3. Aluminium – Aluminio", abbr: "3" },
      { state: "4. Iron/Steel - Hierro / Acero", abbr: "4" },
      { state: "5. Fibreglass – Fibra de vidrio", abbr: "5" },
      { state: "6. Rubber – Caucho", abbr: "6" },
      { state: "7. Cement – Cemento", abbr: "7" },
      { state: "98. Other – Otro", abbr: "98" },
      { state: "99. Unknown - Desconocido", abbr: "99" },
    ],
    select_type_conservation_fish: { state: "", abbr: "" },
    items_select_type_conservation_fish: [
      { state: "1. Fresh – Fresco", abbr: "1" },
      { state: "2. Live – Vivo", abbr: "2" },
      { state: "3. Frozen – Congelado", abbr: "3" },
      { state: "98. Other – Otro", abbr: "98" },
      { state: "99. Unknown - Desconocido", abbr: "99" },
    ],
    select_downspout_material: { state: "", abbr: "" },
    items_select_downspout_material: [
      { state: "Monofilamento", abbr: "1" },
      { state: "Polifilamento", abbr: "2" },
      { state: "Acero", abbr: "3" },
      { state: "Otro, Cual?", abbr: "4" },
      { state: "Polifilamento", abbr: "2" },
    ],
    select_type_hook: { state: "", abbr: "" },
    items_select_type_hook: [
      { state: "Jota", abbr: "1" },
      { state: "Circular", abbr: "2" },
    ],
    select_type_fad: { state: "", abbr: "" },
    items_select_type_fad: [
      { state: "FAD Natural", abbr: "1" },
      { state: "FAD Artificial", abbr: "2" },
      { state: "Payao", abbr: "3" },
      { state: "Arrecife artificial", abbr: "4" },
      { state: "Casita cubana", abbr: "4" },
      { state: "Otro, Cual?", abbr: "4" },
    ],
    select_artifact_material: { state: "", abbr: "" },
    items_select_artifact_material: [
      { state: "Acero", abbr: "1" },
      { state: "Hierro", abbr: "2" },
      { state: "Madera", abbr: "3" },
      { state: "Otro, Cual?", abbr: "4" },
    ],
    select_type_artifact: { state: "", abbr: "" },
    items_select_type_artifact: [
      { state: "Arpones", abbr: "1" },
      { state: "Lanzas", abbr: "2" },
      { state: "Flechas", abbr: "3" },
      { state: "Pinchos", abbr: "4" },
      { state: "Horcas", abbr: "5" },
      { state: "Tenazas", abbr: "6" },
      { state: "Otro, Cual?", abbr: "7" },
    ],
    select_main_trap_material: { state: "", abbr: "" },
    items_select_main_trap_material: [
      { state: "Madera y malla metálica", abbr: "1" },
      { state: "Madera y malla plastica", abbr: "2" },
      { state: "Madera y malla en polifilamento", abbr: "3" },
      { state: "Varilla y malla metálica", abbr: "4" },
      { state: "Varilla y malla plastica", abbr: "5" },
      { state: "Varilla y malla en polifilamento", abbr: "6" },
      { state: "Plástico", abbr: "7" },
      { state: "Otro, Cual?", abbr: "8" },
    ],
    select_denomination_art_fishing: { state: "", abbr: "" },
    items_select_denomination_art_fishing: [
      { state: "Nasas para peces", abbr: "1" },
      { state: "Nasas para crustáceos", abbr: "2" },
      { state: "Nasas para moluscos", abbr: "3" },
      { state: "Otro, Cual?", abbr: "4" },
    ],
    select_mother_line_material: { state: "", abbr: "" },
    items_select_mother_line_material: [
      { state: "Monofilamento", abbr: "1" },
      { state: "Multifilamento", abbr: "2" },
    ],
    select_type_line: { state: "", abbr: "" },
    items_select_type_line: [
      { state: "1. Long line", abbr: "1" },
      { state: "2. Espinel", abbr: "2" },
      { state: "3. Palangre de fondo - real", abbr: "3" },
      { state: "4. Palangre de deriva", abbr: "4" },
      { state: "5. Curricanes", abbr: "5" },
      { state: "6. Ballestilla", abbr: "6" },
      { state: "7. Cordel - Línea de mano", abbr: "7" },
      { state: "98. Otro, Cual?", abbr: "98" },
    ],
    select_propulsion_type: { state: "", abbr: "" },
    items_select_propulsion_type: [
      { state: "1. Vela", abbr: "UNO" },
      { state: "2. Remo", abbr: "DOS" },
      { state: "3. Vara", abbr: "TRES" },
      { state: "4. Motor Fuera de Borda", abbr: "CUATRO" },
      { state: "5. Motor Interno", abbr: "CINCO" },
      { state: "6. Otro", abbr: "SEIS" },
      { state: "7. Desconocido", abbr: "SIETE" },
    ],
    select_vessel_type: { state: "", abbr: "" },
    items_select_vessel_type: [
      { state: "1. TRAWLERS - ARRASTRERO", abbr: "1" },
      { state: "2. SEINERS - CERQUERO", abbr: "2" },
      { state: "3. DREDGERS - RASTRAS", abbr: "3" },
      { state: "4. LIFT NETTERS – REDES IZADAS", abbr: "4" },
      { state: "5. GILLNETTERS - BARCO AGALLERO", abbr: "5" },
      { state: "6. TRAP SETTERS – BARCOS CON TRAMPAS", abbr: "6" },
      { state: "7. LINERS – BARCOS DE LINEA", abbr: "7" },
      {
        state: "8. VESSELS USING PUMPS FOR FISHING - BARCO CON BOMBAS",
        abbr: "8",
      },
      { state: "9. MULTIPURPOSE VESSELS - POLIVALENTES", abbr: "9" },
      {
        state: "10. RECREATIONAL FISHING VESSELS - RECREACIONES",
        abbr: "10",
      },
      { state: "49. FISHING VESSELS NOT SPECIFIED", abbr: "11" },
      { state: "98. OTRO, CUAL?", abbr: "98" },
    ],
    select_authorized_landing_port: null,
    items_select_authorized_landing_port_ind: [
      { nombre: "CP01. BUENAVENTURA", codigo: "UNO" },
      { nombre: "CP02. TUMACO", codigo: "DOS" },
      { nombre: "CP03. TOLU", codigo: "TRES" },
      { nombre: "CP04. CARTAGENA", codigo: "CUATRO" },
      { nombre: "CP05. BARRANQUILLA", codigo: "CINCO" },
      { nombre: "CP07. San Andrés", codigo: "SIETE" },
      { nombre: "CP12. PROVIDENCIA Y SANTA CATALINA", codigo: "DOCE" },

      /*{ state: "Otro, Cual?", abbr: "TREINTA_Y_SEIS" },*/
    ],
    items_select_authorized_landing_port: [
      { nombre: "Nenes Marine - SAI", codigo: "UNO" },
      { nombre: "Gobernación Coral Palace - SAI", codigo: "DOS" },
      { nombre: "Almendros - SAI", codigo: "TRES" },
      { nombre: "San Luis - Bay (La mansión) - SAI", codigo: "CUATRO" },
      { nombre: "San Luis - Bay (Los mellos) - SAI", codigo: "CINCO" },
      { nombre: "Elsy Bar- SAI", codigo: "SEIS" },
      { nombre: "Cove sea side- SAI", codigo: "SIETE" },
      { nombre: "Asomutual - Coopesby pescadero - SAI", codigo: "OCHO" },
      { nombre: "Barrio Obrero - SAI", codigo: "NUEVE" },
      { nombre: "Barrio Los Corales - SAI", codigo: "DIEZ" },
      { nombre: "Av. Newball - SAI", codigo: "ONCE" },
      { nombre: "Spratt Bay - SAI", codigo: "DOCE" },
      { nombre: "Bahia Hooker - SAI", codigo: "TRECE" },
      { nombre: "Perry Hill - SAI", codigo: "CATORCE" },
      { nombre: "Barrio el Bigth - SAI", codigo: "QUINCE" },
      { nombre: "Club Nautico - SAI", codigo: "DIEZ_Y_SEIS" },
      { nombre: "Bahia Manzanillo - Providencia", codigo: "DIEZ_Y_SIETE" },
      { nombre: "Bahia sur oeste - Providencia", codigo: "DIEZ_Y_OCHO" },
      { nombre: "Boxon - Providencia", codigo: "DIEZ_Y_NUEVE" },
      { nombre: "Casa Baja - Providencia", codigo: "VEINTE" },
      {
        nombre: "Cooperativa Fisher and Farm - Providencia",
        codigo: "VEINTIUNO",
      },
      { nombre: "Maracaibo - Providencia", codigo: "VEINTIDOS" },
      { nombre: "Nelly Downs - Providencia", codigo: "VEINTITRES" },
      { nombre: "Pueblo Viejo - Providencia", codigo: "VEINTICUATRO" },
      { nombre: "Punta Rocosa - Providencia", codigo: "VEINTICINCO" },
      { nombre: "San Felipe - Providencia", codigo: "VEINTISEIS" },
      { nombre: "San Juan - Providencia", codigo: "VEINTISIETE" },
      { nombre: "El Cabayete - Providencia", codigo: "VEINTIOCHO" },
      { nombre: "Santa Catalina - Providencia", codigo: "VEINTINUEVE" },
      { nombre: "Bahia Agua Dulce - Providencia", codigo: "TREINTA" },
      { nombre: "Free Town - Providencia", codigo: "TREINTA_Y_UNO" },
      { nombre: "Punta Jones - Providencia", codigo: "TREINTA_Y_DOS" },
      { nombre: "La Montaña - Providencia", codigo: "TREINTA_Y_TRES" },
      { nombre: "Camp - Providencia", codigo: "TREINTA_Y_CUATRO" },
      { nombre: "Santa Isabel - Providencia", codigo: "TREINTA_Y_CINCO" },
      /*{ nombre: "Otro, Cual?", codigo: "TREINTA_Y_SEIS" },*/
    ],

    select_fishing_arts: null,
    items_select_fishing_arts: [
      { nombre: "1. Sedal o de anzuelo", codigo: "UNO" },
      { nombre: "2. Nasas", codigo: "DOS" },
      { nombre: "3. Buceo", codigo: "TRES" },
      /*{ nombre: "98. Otro, Cual?", codigo: "CUATRO" }*/
    ],

    select_fishing_arts_boat: null,
    items_select_fishing_arts_boat: [
      { state: "1. Sedal o de anzuelo", abbr: "UNO" },
      { state: "2. Nasas", abbr: "DOS" },
      { state: "3. Buceo", abbr: "TRES" },
      /*{ state: "4. Otro, Cual?", abbr: "CUATRO" }*/
    ],
    select_fishinng_permission_validity: { nombre: "", codigo: "" },
    items_select_fishinng_permission_validity: [
      { nombre: "1", codigo: "UNO" },
      { nombre: "2", codigo: "DOS" },
      { nombre: "3", codigo: "TRES" },
      { nombre: "4", codigo: "CUATRO" },
      { nombre: "5", codigo: "CINCO" },
    ],
    select_authorized_type: null,
    items_select_authorized_type: [
      { state: "1. Pesca Comercial Industrial", abbr: "UNO" },
      { state: "2. Permiso Integrado de Pesca", abbr: "DOS" },
    ],
    select_current_state_pavilion: { state: "", abbr: "" },
    items_select_current_state_pavilion: [
      { state: "AFG. Afghanistan", abbr: "AFG" },
      { state: "ALB. Albania", abbr: "ALB" },
      { state: "DZA. Algeria", abbr: "DZA" },
      { state: "ASM. American Samoa", abbr: "ASM" },
      { state: "AND. Andorra", abbr: "AND" },
      { state: "AGO. Angola", abbr: "AGO" },
      { state: "AIA. Anguilla", abbr: "AIA" },
      { state: "ATA. Antarctica", abbr: "ATA" },
      { state: "ATG. Antigua and Barbuda", abbr: "ATG" },
      { state: "ARG. Argentina", abbr: "ARG" },
      { state: "ARM. Armenia", abbr: "ARM" },
      { state: "ABW. Aruba", abbr: "ABW" },
      { state: "AUS. Australia", abbr: "AUS" },
      { state: "AUT. Austria", abbr: "AUT" },
      { state: "AZE. Azerbaijan", abbr: "AZE" },
      { state: "BHS. Bahamas", abbr: "BHS" },
      { state: "BHR. Bahrain", abbr: "BHR" },
      { state: "BGD. Bangladesh", abbr: "BGD" },
      { state: "BRB. Barbados", abbr: "BRB" },
      { state: "BLR. Belarus", abbr: "BLR" },
      { state: "BEL. Belgium", abbr: "BEL" },
      { state: "BLZ. Belize", abbr: "BLZ" },
      { state: "BEN. Benin", abbr: "BEN" },
      { state: "BMU. Bermuda", abbr: "BMU" },
      { state: "BTN. Bhutan", abbr: "BTN" },
      { state: "BO. Bolivia", abbr: "BO" },
      { state: "BOL. Bolivia", abbr: "BOL" },
      { state: "BIH. Bosnia and Herzegovina", abbr: "BIH" },
      { state: "BWA. Botswana", abbr: "BWA" },
      { state: "BVT. Bouvet Island", abbr: "BVT" },
      { state: "BRA. Brazil", abbr: "BRA" },
      { state: "IOT. British Indian Ocean Territory", abbr: "IOT" },
      { state: "BRN. Brunei Darussalam", abbr: "BRN" },
      { state: "BRN. Brunei", abbr: "BRN" },
      { state: "BGR. Bulgaria", abbr: "BGR" },
      { state: "BFA. Burkina Faso", abbr: "BFA" },
      { state: "BDI. Burundi", abbr: "BDI" },
      { state: "KHM. Cambodia", abbr: "KHM" },
      { state: "CMR. Cameroon", abbr: "CMR" },
      { state: "CAN. Canada", abbr: "CAN" },
      { state: "CPV. Cape Verde", abbr: "CPV" },
      { state: "CYM. Cayman Islands", abbr: "CYM" },
      { state: "CAF. Central African Republic", abbr: "CAF" },
      { state: "TCD. Chad", abbr: "TCD" },
      { state: "CHL. Chile", abbr: "CHL" },
      { state: "CHN. China", abbr: "CHN" },
      { state: "CXR. Christmas Island", abbr: "CXR" },
      { state: "CCK. Cocos (Keeling) Islands", abbr: "CCK" },
      { state: "COL. Colombia", abbr: "COL" },
      { state: "COM. Comoros", abbr: "COM" },
      { state: "COG. Congo", abbr: "COG" },
      { state: "CD. Congo", abbr: "CD" },
      { state: "COK. Cook Islands", abbr: "COK" },
      { state: "CRI. Costa Rica", abbr: "CRI" },
      { state: "CIV. CÃ´te d'Ivoire", abbr: "CIV" },
      { state: "CIV. Ivory Coast", abbr: "CIV" },
      { state: "HRV. Croatia", abbr: "HRV" },
      { state: "CUB. Cuba", abbr: "CUB" },
      { state: "CYP. Cyprus", abbr: "CYP" },
      { state: "CZE. Czech Republic", abbr: "CZE" },
      { state: "DNK. Denmark", abbr: "DNK" },
      { state: "DJI. Djibouti", abbr: "DJI" },
      { state: "DMA. Dominica", abbr: "DMA" },
      { state: "DOM. Dominican Republic", abbr: "DOM" },
      { state: "ECU. Ecuador", abbr: "ECU" },
      { state: "EGY. Egypt", abbr: "EGY" },
      { state: "SLV. El Salvador", abbr: "SLV" },
      { state: "GNQ. Equatorial Guinea", abbr: "GNQ" },
      { state: "ERI. Eritrea", abbr: "ERI" },
      { state: "EST. Estonia", abbr: "EST" },
      { state: "ETH. Ethiopia", abbr: "ETH" },
      { state: "FLK. Falkland Islands (Malvinas)", abbr: "FLK" },
      { state: "FRO. Faroe Islands", abbr: "FRO" },
      { state: "FJI. Fiji", abbr: "FJI" },
      { state: "FIN. Finland", abbr: "FIN" },
      { state: "FRA. France", abbr: "FRA" },
      { state: "GUF. French Guiana", abbr: "GUF" },
      { state: "PYF. French Polynesia", abbr: "PYF" },
      { state: "ATF. French Southern Territories", abbr: "ATF" },
      { state: "GAB. Gabon", abbr: "GAB" },
      { state: "GMB. Gambia", abbr: "GMB" },
      { state: "GEO. Georgia", abbr: "GEO" },
      { state: "DEU. Germany", abbr: "DEU" },
      { state: "GHA. Ghana", abbr: "GHA" },
      { state: "GIB. Gibraltar", abbr: "GIB" },
      { state: "GRC. Greece", abbr: "GRC" },
      { state: "GRL. Greenland", abbr: "GRL" },
      { state: "GRD. Grenada", abbr: "GRD" },
      { state: "GLP. Guadeloupe", abbr: "GLP" },
      { state: "GUM. Guam", abbr: "GUM" },
      { state: "GTM. Guatemala", abbr: "GTM" },
      { state: "GGY. Guernsey", abbr: "GGY" },
      { state: "GIN. Guinea", abbr: "GIN" },
      { state: "GNB. Guinea-Bissau", abbr: "GNB" },
      { state: "GUY. Guyana", abbr: "GUY" },
      { state: "HTI. Haiti", abbr: "HTI" },
      { state: "HMD. Heard Island and McDonald Islands", abbr: "HMD" },
      { state: "VAT. Holy See (Vatican City State)", abbr: "VAT" },
      { state: "HND. Honduras", abbr: "HND" },
      { state: "HKG. Hong Kong", abbr: "HKG" },
      { state: "HUN. Hungary", abbr: "HUN" },
      { state: "ISL. Iceland", abbr: "ISL" },
      { state: "IND. India", abbr: "IND" },
      { state: "IDN. Indonesia", abbr: "IDN" },
      { state: "IR. Iran", abbr: "IR" },
      { state: "IRQ. Iraq", abbr: "IRQ" },
      { state: "IRL. Ireland", abbr: "IRL" },
      { state: "IMN. Isle of Man", abbr: "IMN" },
      { state: "ISR. Israel", abbr: "ISR" },
      { state: "ITA. Italy", abbr: "ITA" },
      { state: "JAM. Jamaica", abbr: "JAM" },
      { state: "JPN. Japan", abbr: "JPN" },
      { state: "JEY. Jersey", abbr: "JEY" },
      { state: "JOR. Jordan", abbr: "JOR" },
      { state: "KAZ. Kazakhstan", abbr: "KAZ" },
      { state: "KEN. Kenya", abbr: "KEN" },
      { state: "KIR. Kiribati", abbr: "KIR" },
      { state: "KP. Korea", abbr: "KP" },
      { state: "KR. Korea", abbr: "KR" },
      { state: "KOR. South Korea", abbr: "KOR" },
      { state: "KWT. Kuwait", abbr: "KWT" },
      { state: "KGZ. Kyrgyzstan", abbr: "KGZ" },
      { state: "LAO. Lao People's Democratic Republic", abbr: "LAO" },
      { state: "LVA. Latvia", abbr: "LVA" },
      { state: "LBN. Lebanon", abbr: "LBN" },
      { state: "LSO. Lesotho", abbr: "LSO" },
      { state: "LBR. Liberia", abbr: "LBR" },
      { state: "LBY. Libyan Arab Jamahiriya", abbr: "LBY" },
      { state: "LBY. Libya", abbr: "LBY" },
      { state: "LIE. Liechtenstein", abbr: "LIE" },
      { state: "LTU. Lithuania", abbr: "LTU" },
      { state: "LUX. Luxembourg", abbr: "LUX" },
      { state: "MAC. Macao", abbr: "MAC" },
      { state: "MK. Macedonia", abbr: "MK" },
      { state: "MDG. Madagascar", abbr: "MDG" },
      { state: "MWI. Malawi", abbr: "MWI" },
      { state: "MYS. Malaysia", abbr: "MYS" },
      { state: "MDV. Maldives", abbr: "MDV" },
      { state: "MLI. Mali", abbr: "MLI" },
      { state: "MLT. Malta", abbr: "MLT" },
      { state: "MHL. Marshall Islands", abbr: "MHL" },
      { state: "MTQ. Martinique", abbr: "MTQ" },
      { state: "MRT. Mauritania", abbr: "MRT" },
      { state: "MUS. Mauritius", abbr: "MUS" },
      { state: "MYT. Mayotte", abbr: "MYT" },
      { state: "MEX. Mexico", abbr: "MEX" },
      { state: "FM. Micronesia", abbr: "FM" },
      { state: "MD. Moldova", abbr: "MD" },
      { state: "MCO. Monaco", abbr: "MCO" },
      { state: "MNG. Mongolia", abbr: "MNG" },
      { state: "MNE. Montenegro", abbr: "MNE" },
      { state: "MSR. Montserrat", abbr: "MSR" },
      { state: "MAR. Morocco", abbr: "MAR" },
      { state: "MOZ. Mozambique", abbr: "MOZ" },
      { state: "MMR. Myanmar", abbr: "MMR" },
      { state: "MMR. Burma", abbr: "MMR" },
      { state: "NAM. Namibia", abbr: "NAM" },
      { state: "NRU. Nauru", abbr: "NRU" },
      { state: "NPL. Nepal", abbr: "NPL" },
      { state: "NLD. Netherlands", abbr: "NLD" },
      { state: "ANT. Netherlands Antilles", abbr: "ANT" },
      { state: "NCL. New Caledonia", abbr: "NCL" },
      { state: "NZL. New Zealand", abbr: "NZL" },
      { state: "NIC. Nicaragua", abbr: "NIC" },
      { state: "NER. Niger", abbr: "NER" },
      { state: "NGA. Nigeria", abbr: "NGA" },
      { state: "NIU. Niue", abbr: "NIU" },
      { state: "NFK. Norfolk Island", abbr: "NFK" },
      { state: "MNP. Northern Mariana Islands", abbr: "MNP" },
      { state: "NOR. Norway", abbr: "NOR" },
      { state: "OMN. Oman", abbr: "OMN" },
      { state: "PAK. Pakistan", abbr: "PAK" },
      { state: "PLW. Palau", abbr: "PLW" },
      { state: "PS. Palestinian Territory", abbr: "PS" },
      { state: "PAN. Panama", abbr: "PAN" },
      { state: "PNG. Papua New Guinea", abbr: "PNG" },
      { state: "PRY. Paraguay", abbr: "PRY" },
      { state: "PER. Peru", abbr: "PER" },
      { state: "PHL. Philippines", abbr: "PHL" },
      { state: "PCN. Pitcairn", abbr: "PCN" },
      { state: "POL. Poland", abbr: "POL" },
      { state: "PRT. Portugal", abbr: "PRT" },
      { state: "PRI. Puerto Rico", abbr: "PRI" },
      { state: "QAT. Qatar", abbr: "QAT" },
      { state: "REU. RÃ©union", abbr: "REU" },
      { state: "ROU. Romania", abbr: "ROU" },
      { state: "RUS. Russian Federation", abbr: "RUS" },
      { state: "RUS. Russia", abbr: "RUS" },
      { state: "RWA. Rwanda", abbr: "RWA" },
      { state: "SH. Saint Helena", abbr: "SH" },
      { state: "KNA. Saint Kitts and Nevis", abbr: "KNA" },
      { state: "LCA. Saint Lucia", abbr: "LCA" },
      { state: "SPM. Saint Pierre and Miquelon", abbr: "SPM" },
      { state: "VCT. Saint Vincent and the Grenadines", abbr: "VCT" },
      { state: "VCT. Saint Vincent & the Grenadines", abbr: "VCT" },
      { state: "VCT. St. Vincent and the Grenadines", abbr: "VCT" },
      { state: "WSM. Samoa", abbr: "WSM" },
      { state: "SMR. San Marino", abbr: "SMR" },
      { state: "STP. Sao Tome and Principe", abbr: "STP" },
      { state: "SAU. Saudi Arabia", abbr: "SAU" },
      { state: "SEN. Senegal", abbr: "SEN" },
      { state: "SRB. Serbia", abbr: "SRB" },
      { state: "SYC. Seychelles", abbr: "SYC" },
      { state: "SLE. Sierra Leone", abbr: "SLE" },
      { state: "SGP. Singapore", abbr: "SGP" },
      { state: "SVK. Slovakia", abbr: "SVK" },
      { state: "SVN. Slovenia", abbr: "SVN" },
      { state: "SLB. Solomon Islands", abbr: "SLB" },
      { state: "SOM. Somalia", abbr: "SOM" },
      { state: "ZAF. South Africa", abbr: "ZAF" },
      {
        state: "SGS. South Georgia and the South Sandwich Islands",
        abbr: "SGS",
      },
      { state: "ESP. Spain", abbr: "ESP" },
      { state: "LKA. Sri Lanka", abbr: "LKA" },
      { state: "SDN. Sudan", abbr: "SDN" },
      { state: "SUR. Suriname", abbr: "SUR" },
      { state: "SJM. Svalbard and Jan Mayen", abbr: "SJM" },
      { state: "SWZ. Swaziland", abbr: "SWZ" },
      { state: "SWE. Sweden", abbr: "SWE" },
      { state: "CHE. Switzerland", abbr: "CHE" },
      { state: "SYR. Syrian Arab Republic", abbr: "SYR" },
      { state: "TW. Taiwan", abbr: "TW" },
      { state: "TWN. Taiwan", abbr: "TWN" },
      { state: "TJK. Tajikistan", abbr: "TJK" },
      { state: "TZ. Tanzania", abbr: "TZ" },
      { state: "THA. Thailand", abbr: "THA" },
      { state: "TLS. Timor-Leste", abbr: "TLS" },
      { state: "TGO. Togo", abbr: "TGO" },
      { state: "TKL. Tokelau", abbr: "TKL" },
      { state: "TON. Tonga", abbr: "TON" },
      { state: "TTO. Trinidad and Tobago", abbr: "TTO" },
      { state: "TTO. Trinidad & Tobago", abbr: "TTO" },
      { state: "TUN. Tunisia", abbr: "TUN" },
      { state: "TUR. Turkey", abbr: "TUR" },
      { state: "TKM. Turkmenistan", abbr: "TKM" },
      { state: "TCA. Turks and Caicos Islands", abbr: "TCA" },
      { state: "TUV. Tuvalu", abbr: "TUV" },
      { state: "UGA. Uganda", abbr: "UGA" },
      { state: "UKR. Ukraine", abbr: "UKR" },
      { state: "ARE. United Arab Emirates", abbr: "ARE" },
      { state: "GBR. United Kingdom", abbr: "GBR" },
      { state: "USA. United States", abbr: "USA" },
      { state: "UMI. United States Minor Outlying Islands", abbr: "UMI" },
      { state: "URY. Uruguay", abbr: "URY" },
      { state: "UZB. Uzbekistan", abbr: "UZB" },
      { state: "VUT. Vanuatu", abbr: "VUT" },
      { state: "VE. Venezuela", abbr: "VE" },
      { state: "VEN. Venezuela", abbr: "VEN" },
      { state: "VNM. Viet Nam", abbr: "VNM" },
      { state: "VNM. Vietnam", abbr: "VNM" },
      { state: "VG. Virgin Islands", abbr: "VG" },
      { state: "VI. Virgin Islands", abbr: "VI" },
      { state: "WLF. Wallis and Futuna", abbr: "WLF" },
      { state: "ESH. Western Sahara", abbr: "ESH" },
      { state: "YEM. Yemen", abbr: "YEM" },
      { state: "ZMB. Zambia", abbr: "ZMB" },
      { state: "ZW. Zimbabwe", abbr: "ZWE" },
    ],
    select_authorized_arts: [],
    items_select_authorized_arts: [
      { nombre: "1. SURROUNDING NETS – Red de Cerco", codigo: "UNO" },
      { nombre: "2. SEINE NETS – Redes de tiro", codigo: "DOS" },
      { nombre: "3. TRAWLS – Redes de arrastre", codigo: "TRES" },
      { nombre: "4. DREDGES – RASTRAS", codigo: "CUATRO" },
      { nombre: "5. LIFT NETS – Redes izadas", codigo: "CINCO" },
      { nombre: "6. FALLING GEAR – Redes de caida", codigo: "SEIS" },
      {
        nombre: "7. GILLNETS AND ENTANGLING NETS- Red agallera y de enmalle",
        codigo: "SIETE",
      },
      { nombre: "8. TRAPS - Trampas", codigo: "OCHO" },
      { nombre: "9. HOOKS AND LINES- Líneas y anzuelos", codigo: "NUEVE" },
      {
        nombre: "10. GRAPPLING AND WOUNDING – artes de aferrar o herir",
        codigo: "DIEZ",
      },
      {
        nombre: "11. HARVESTING MACHINES - Máquinas de recolección",
        codigo: "ONCE",
      },
      {
        nombre: "20. MISCELLANEOUS GEAR - artes diversos - polivalente",
        codigo: "VEINTE",
      },
      {
        nombre: "25. RECREATIONAL FISHING GEAR – artes de uso recreativo",
        codigo: "VEINTICINCO",
      },
      { nombre: "6. FALLING GEAR – Redes de caida", codigo: "SEIS" },
      {
        nombre: "99. Unknown - Desconocido",
        codigo: "NOVENTAYNUEVE",
      },
      { nombre: "8. TRAPS - Trampas", codigo: "OCHO" },
    ],

    desserts: [],
    editedIndex: -1,
    editedItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
    defaultItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
    boat: {},
    pesca: null,
    cod_nit: "",
  }),

  directives: { money: VMoney },

  validations: {
    nit_modal: {
      required,
    },
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    ...mapGetters(["Statute", "allOrganizations"]),
    itemsSelectOrganizations(){
      return this.allOrganizations.map(x => ({abbr: x.id, state: x.abbr_name}))
    }
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    itemsSelectOrganizations() {
     this.select_organization_name = this.itemsSelectOrganizations.find(x => x.abbr == this.select_organization_name);
    }
  },
 
  created: async function () {
    await this.$store.dispatch("getStatute", this.id);

    const statute = this.Statute;
    this.location = this.items_location.find((x) => x.abbr == statute.location);
    if (statute.permisionario != null) {
      this.setCompany(statute.permisionario);
    }
    if (statute.boat != null) {
      this.setBoat(statute.boat);
      this.boat = statute.boat;
    }
    if (statute.fisherman != null) {
      this.setFishermen(statute.fisherman);
    }
    if (statute.pesca != null) {
      this.setPesca(statute.pesca);
    }
    if (statute.permission != null) {
      this.setPermitte(statute.permission);
    }
    if (statute.estado == 2 && this.process != "view") {
      this.$router.go(-1);
    }
    if(this.type === 'art' && !this.allOrganizations.length){
      this.getOrganizations();
    }

    const concepto = await consultar.fetch();
    this.items_select_fishing_arts = concepto.items_select_fishing_arts;
    this.items_select_types_of_fishing = concepto.items_select_types_of_fishing;
    this.items_select_authorized_arts = concepto.items_select_authorized_arts;
  },

  methods: {
    ...mapActions(["getOrganizations"]),
    saveFishermen() {
      const postData = {
        id: this.Statute.fishermen,
        name: this.name,
        lastname: this.lastname,
        identification_number: this.identification_number,
        id_type: this.select_type_document.abbr,
        type_person: this.select_type_person.abbr,
        occre: this.occre,
        no_occre: this.no_occre,
        nationality: this.select_nationality.abbr,
        phone: this.phone,
        email: this.email,
        address: this.address,
        organization_cooperative: this.organization_cooperative,
        organization_name: this.select_organization?.abbr
      };

      this.axios
        .post(saveFishermenInvUrl, postData, { headers: getHeader() })
        .then((response) => {
          this.setFishermen(response.data);
          this.dialogNewCompany = false;
        });
    },
    saveCompany() {
      this.dialogNewCompany = false;

      if (this.select_id_type_modal != null) {
        if (this.select_id_type_modal.abbr == null) {
          var authorized_type = this.select_id_type_modal;
          this.select_id_type_modal = { state: authorized_type, abbr: "OTHER" };
        }
      } else {
        this.select_id_type_modal = { state: "", abbr: "" };
      }

      const postData = {
        company_id: this.Statute.company,
        type_of_company: this.select_type_company_modal.abbr,
        nit: this.nit_modal,
        cod_nit: this.cod_nit,
        name: this.name_modal,
        legal_representative: this.legal_representative_modal,
        id_type: this.select_id_type_modal.abbr,
        id_type_state: this.select_id_type_modal.state,
        identification_number: this.identification_number_modal,
        address: this.address_modal,
        phone: this.phone_modal,
        email: this.email_modal,
      };

      this.axios
        .post(saveCompanyInvUrl, postData, { headers: getHeader() })
        .then((response) => {
          this.setCompany(response.data);
          this.dialogNewCompany = false;
        });
    },
    setFishermen(item) {
      this.name = item.name;
      this.name_show = item.name;
      this.lastname = item.lastname;
      this.lastname_show = item.lastname;

      if (item.id_type != null)
        this.select_type_document = this.items_select_type_document.find(
          (x) => x.abbr == item.id_type
        );
      this.select_type_document_show = this.items_select_type_document.find(
        (x) => x.abbr == item.id_type
      );
      this.select_type_document = this.items_select_type_document.find(
        (x) => x.abbr == item.id_type
      );
      if (item.type_person != null)
        this.select_type_person = this.items_select_research_type_person.find(
          (x) => x.abbr == item.type_person
        );
      this.select_type_person_show =
        this.items_select_research_type_person.find(
          (x) => x.abbr == item.type_person
        );
      if (item.organization) {
        this.select_organization = {abbr: item.organization?.id, state: item.organization?.abbr_name}
        this.select_organization_show = this.select_organization
      }

      if (item.organization_cooperative != null) {
        this.organization_cooperative_show = item.organization_cooperative;
      }

      this.identification_number = item.identification_number;
      this.identification_number_show = item.identification_number;
      this.occre = item.occre;
      this.occre_show = item.occre;
      this.no_occre = item.no_occre;
      this.no_occre_show = item.no_occre;

      if (item.nationality != null)
        this.select_nationality = this.items_select_nationality.find(
          (x) => x.abbr.replace(" ", "") == item.nationality
        );
      this.select_nationality_show = this.items_select_nationality.find(
        (x) => x.abbr.replace(" ", "") == item.nationality
      );

      this.email = item.email;
      this.email_show = item.email;

      this.address = item.address;
      this.address_show = item.address;
      this.phone = item.phone;
      this.phone_show = item.phone;
    },
    Valid(model) {
      const pattern =
        /^-?[0-9]{1,3}([,][0-9]+)?(°)-?[0-9]{1,3}([,][0-9]+)?(')-?[0-9]{1,3}([,][0-9]+)?(")([NSEO])$/;
      // (°)[0-9]{1,3}(')[0-9]{1,3}(")(N)

      return pattern.test(model);
    },
    setCompany(company) {
      this.dialogPermisionario = false;
      this.select_type_of_company = company.type_of_company;
      this.select_type_company_modal.abbr = company.type_of_company;
      this.nit = company.nit;
      this.nit_modal = company.nit;
      this.cod_nit = company.cod_nit;

      this.name = company.name;
      this.name_modal = company.name;
      this.legal_representative = company.legal_representative;
      this.legal_representative_modal = company.legal_representative;
      this.select_type_identification = company.id_type;
      if (this.select_id_type_modal == null) {
        this.select_id_type_modal = { state: "", abbr: "" };
      }
      this.select_id_type_modal = this.items_select_id_type_modal.find(
        (x) => x.abbr == company.id_type
      );
      this.identification_number = company.identification_number;
      this.identification_number_modal = company.identification_number;
      this.address = company.address;
      this.address_modal = company.address;
      this.phone = company.phone;
      this.phone_modal = company.phone;
      this.email = company.email;
      this.email_modal = company.email;
      this.radiosFishingCards = company.fishing_card === 1 ? "SI" : "NO";
      this.fishingCardNumber = company.fishing_card_number;
      this.radiosOrganization = company.organization === 1 ? "SI" : "NO";
      this.select_organization_name = company.organization_name;
    },

    setPermitte(permision) {
      this.showPermissionIn = true;

      this.type = permision.type_permission;
      this.date_effective_authorization_init = new Date(
        permision.effective_activation_date
      )
        .toISOString()
        .substr(0, 10);
      this.date_effective_authorization_finish = new Date(
        permision.effective_inactivation_date
      )
        .toISOString()
        .substr(0, 10);

      if (permision.duration_of_permit_granted != null)
        this.select_fishinng_permission_validity =
          this.items_select_fishinng_permission_validity.find(
            (x) => x.codigo == permision.duration_of_permit_granted
          );
      this.date_issue = permision.date_of_issue;
      this.date_notification = permision.date_of_notification;

      this.select_authorized_arts = permision.authorized_arts.split(",");
      this.estado = permision.estado;

      if (permision.authorized_area != null)
        this.select_authorized_zone = this.items_select_authorized_zone.find(
          (x) => x.codigo == permision.authorized_area
        );
      if (permision.issued_by != null)
        this.select_issued_by = this.items_select_issued_by.find(
          (x) => x.codigo == permision.issued_by
        );

      if (permision.landing_zone != null)
        this.select_authorized_landing_port =
          this.type == "art"
            ? this.items_select_authorized_landing_port.find(
                (x) => x.codigo == permision.landing_zone
              )
            : this.items_select_authorized_landing_port_ind.find(
                (x) => x.codigo == permision.landing_zone
              );

      if (this.select_authorized_landing_port == undefined) {
        permision.landing_zone;
      }

      this.authorization_number = permision.authorization_number;
      this.permission_id = permision.id;
      this.select_marketing_authorization = permision.titular_autorizacion;
      this.select_authorized_species = permision.especies_auth;
      this.select_types_of_fishing = permision.type_fishery;
      this.select_fishing_arts = permision.fishing_arts;
    },

    saveBoat() {
      this.dialogBoat = false;

      let postData = {
        boat: this.Statute.boat_id,
        registration_number: this.external_marking,

        current_state_pavilion: this.select_current_state_pavilion.abbr,
        boat_name: this.vessel_name,
        registration_port: this.select_registration_port.abbr,
        boat_type: this.select_vessel_type.abbr,
        fishing_arts_boat: this.select_fishing_arts_boat.abbr,
        fishing_arts_boat_state: this.select_fishing_arts_boat.state,
        approx_boat_value: this.value_vessel,
        sell_products_to: this.sell_products_to,
        length_overall: this.eslora_length,
        beam_extreme_breadth: this.extreme_breadth,
        net_registered_tonnage: this.registration_tonnage,
        gross_registered_tonnage: this.registration_tonnage_gross,
        type_of_propulsion: this.select_propulsion_type.abbr,
        main_engines_power: this.main_motor_power,
        power_unit: this.select_power_unit.abbr,
        hull_material: this.select_helmet_material.abbr,
        warehouses_number: this.number_wineries,
        fish_hold_high: this.tall_wineries,
        fish_hold_long: this.long_wineries,
        fish_hold_width: this.width_wineries,
        fish_hold_capacity: this.capacity_wineries,
        fish_hold_type: this.select_type_conservation_fish.abbr,
        number_of_crew: this.crew_number,
        number_of_fishermens: this.fishermen_number,

        fishing_arts_value: this.fishing_value,
        fishing_zone: this.fishing_zone,
        fishing_line: this.select_type_line.abbr,
        hook_type: this.select_type_hook.abbr,
        hook_size: this.hook_size_type,
        hooks_number: this.hook_quantity,
        mother_line_length: this.mother_line_length,
        mother_line_material: this.select_mother_line_material.abbr,
        downpipe_material: this.select_downspout_material.abbr,
        total_lines_number: this.total_number_lines,
        art_type_traps: this.select_denomination_art_fishing.abbr,
        type_traps_number: this.cheating_amount,
        type_traps_material: this.select_main_trap_material.abbr,
        type_wound_artifacts: this.select_type_artifact.abbr,
        amount_artifacts: this.number_artifacts,
        artifacts_material: this.select_artifact_material.abbr,
        radiosChart: this.radiosChart,
        radiosMotoNave: this.radiosMotoNave,
        radiosMagneticCompass: this.radiosMagneticCompass,
        current_fishing_permit: this.radiosCurrentFishingPermit,
      };

      this.axios
        .post(saveBoatViolationStatuteArtUrl, postData, {
          headers: getHeader(),
        })
        .then((response) => {
          this.setBoat(response.data);
        });
    },
    setPesca(pesca) {
      this.pesca = pesca.id;
      if (pesca.reporting_institution != null)
        this.select_reporting_institution =
          this.items_select_reporting_institution.find(
            (x) => x.abbr == pesca.reporting_institution
          );
      if (pesca.fishing_gear != null)
        this.select_entity_fishing_gear =
          this.items_select_entity_fishing_gear.find(
            (x) => x.abbr == pesca.fishing_gear
          );
      this.type_fact_location_longitude = pesca.type_fact_location_longitude;
      this.type_fact_location_latitude = pesca.type_fact_location_latitude;
      if (pesca.entity_product_seized != null)
        this.select_entity_product_seized =
          this.items_select_entity_product_seized.find(
            (x) => x.abbr == pesca.entity_product_seized
          );
      if (pesca.entity_vessel_boat != null)
        this.select_entity_vessel_boat =
          this.items_select_entity_vessel_boat.find(
            (x) => x.abbr == pesca.entity_vessel_boat
          );
      if (pesca.research_type_person != null)
        this.select_research_type_person =
          this.items_select_research_type_person.find(
            (x) => x.abbr == pesca.research_type_person
          );
      if (pesca.type_id != null)
        this.select_type_document = this.items_select_type_document.find(
          (x) => x.abbr == pesca.type_id
        );
      if (pesca.number_id != null) this.number_id = pesca.number_id;

      this.type_product_seized = pesca.type_product_seized;
      this.amount_product_seized = pesca.amount_product_seized;
      this.nur = pesca.nur;
      if (pesca.research_type_person != null)
        this.select_research_type_person =
          this.items_select_research_type_person.find(
            (x) => x.abbr == pesca.research_type_person
          );
      this.name_research_person = pesca.name_research_person;
      this.select_infringement_process = pesca.infracciones_process;
      this.illegal_fishing_date = pesca.sanction_date;
      this.radiosStatuteViolation = pesca.fishing_statute_violation;
      this.administrative_act_number = pesca.administrative_act_number;
      this.radiosPenaltyStatute = pesca.penalty_statute_sanctions;
      this.select_penalty_infringement = pesca.infracciones_sanctioned;
      if (pesca.tipo_infracciones.length > 0)
        this.select_penalty_infringement_type = pesca.tipo_infracciones;
      if (pesca.failure_executed != null)
        this.select_failure_executed = this.items_select_failure_executed.find(
          (x) => x.abbr == pesca.failure_executed
        );

      this.description_or_observation = pesca.description_or_observation;
      this.fine_value = pesca.fine_value == null ? 0 : pesca.fine_value;
      this.reposicion = pesca.reposicion;
      this.ejecutoriado = pesca.ejecutoriado;
      this.description_reposicion = pesca.descripcion_reposicion;
      this.description_ejecutoriado = pesca.descripcion_ejecutoriado;
    },
    saveStatuteViolation(process) {
      let postData = {
        estado:
          this.radiosPenaltyStatute == "NO"
            ? 2
            : this.process == "new"
            ? 1
            : this.process == "resolv"
            ? 2
            : null,
        failure_executed:
          this.process == "new" ? 0 : this.process == "resolv" ? 1 : null,
        pesca: this.pesca,
        location: this.location.abbr,
        investigation_id: this.id,
        reporting_institution: this.select_reporting_institution.abbr,
        fishing_gear: this.select_entity_fishing_gear.abbr,
        type_fact_location_longitude: this.type_fact_location_longitude,
        type_fact_location_latitude: this.type_fact_location_latitude,
        entity_vessel_boat: this.select_entity_vessel_boat.abbr,
        type_product_seized: this.type_product_seized,
        entity_product_seized: this.select_entity_product_seized.abbr,
        amount_product_seized: this.amount_product_seized,
        nur: this.nur,
        research_type_person: this.select_research_type_person.abbr,
        name_research_person: this.name_research_person,
        infringement_process: this.select_infringement_process,
        sanction_date: this.illegal_fishing_date,
        fishing_statute_violation: this.radiosStatuteViolation,
        administrative_act_number: this.administrative_act_number,
        penalty_statute_sanctions: this.radiosPenaltyStatute,
        penalty_infringement_type: this.select_penalty_infringement_type,
        penalty_infringement: this.select_penalty_infringement,
        date_report: this.date_report,
        fine_value:
          this.select_penalty_infringement_type.find(
            (x) => x.codigo == "DOS"
          ) != undefined
            ? this.fine_value
            : 0,
        sanction_state: this.select_sanction_state.abbr,
        description_or_observation: this.description_or_observation,
        reposicion: this.reposicion,
        ejecutoriado: this.ejecutoriado,
        descripcion_reposicion:
          this.reposicion == "SI" ? this.description_reposicion : null,
        descripcion_ejecutoriado:
          this.ejecutoriado == "SI" ? this.description_ejecutoriado : null,
      };

      if (process == "close") {
        swal("Seguro desea continuar", {
          buttons: {
            cancel: "Cancelar",

            Si: true,
          },
        }).then((value) => {
          switch (value) {
            case "Si":
              this.axios
                .post(saveStatuteViolationUrl, postData, {
                  headers: getHeader(),
                })
                .then(
                  () => {
                    this.$router.push({
                      path: "/investigation-by-violation-statute",
                    });
                  },
                  () => {
                    this.showStatuteViolationAlert = true;
                    this.messageStatuteViolationArt =
                      "La investigacion por violación al estatuto ya existe";
                  }
                );
              break;

            default:
              return false;
          }
        });
      } else {
        this.axios
          .post(saveStatuteViolationUrl, postData, { headers: getHeader() })
          .then(
            () => {
              this.$router.push({
                path: "/investigation-by-violation-statute",
              });
            },
            () => {
              this.showStatuteViolationAlert = true;
              this.messageStatuteViolationArt =
                "La investigacion por violación al estatuto ya existe";
            }
          );
      }
    },
    setBoat(boat) {
      this.boatId = boat.id;
      (this.company_id = boat.company_id),
        (this.boat_name = boat.boat_name + " | " + boat.registration_number);
      const default_value = { abbr: "", state: "" };
      this.select_registration_port = default_value;
      this.select_vessel_type = default_value;
      this.select_fishing_arts_boat = [];
      this.select_type_line = default_value;
      this.select_type_hook = default_value;
      this.select_mother_line_material = default_value;
      this.select_downspout_material = default_value;
      this.select_denomination_art_fishing = default_value;
      this.select_main_trap_material = default_value;
      this.select_type_artifact = default_value;
      this.select_artifact_material = default_value;
      this.select_type_fad = default_value;

      this.calado = boat.calado;
      this.expedition_by = boat.expedition_by;
      this.boat_number = boat.boat_unique_id_state;
      this.external_marking = boat.registration_number;
      this.place_issue_registration = boat.expedition_place;
      this.date_issue_registration = boat.expedition_date;
      this.date_expiration_registration = boat.expiration_date;
      if (boat.current_state_pavilion != null)
        if (
          this.items_select_current_state_pavilion.find(
            (x) => x.abbr == boat.current_state_pavilion
          ) != undefined
        )
          this.select_current_state_pavilion =
            this.items_select_current_state_pavilion.find(
              (x) => x.abbr == boat.current_state_pavilion
            );
      this.vessel_name = boat.boat_name;
      if (boat.registration_port != null) {
        if (
          this.items_select_registration_port.find(
            (x) => x.abbr == boat.registration_port
          ) != undefined
        ) {
          this.select_registration_port =
            this.items_select_registration_port.find(
              (x) => x.abbr == boat.registration_port
            );
        } else this.select_registration_port = boat.registration_port;
      }

      if (boat.boat_type != null)
        if (
          this.items_select_vessel_type.find((x) => x.abbr == boat.boat_type) !=
          undefined
        )
          this.select_vessel_type = this.items_select_vessel_type.find(
            (x) => x.abbr == boat.boat_type
          );
      if (boat.boat_type_state != null)
        this.select_vessel_type.state = boat.boat_type_state;
      this.value_vessel = boat.approx_boat_value;

      this.sell_products_to = boat.sell_products_to;
      this.eslora_length = boat.length_overall;
      this.extreme_breadth = boat.beam_extreme_breadth;
      this.registration_tonnage = boat.net_registered_tonnage;
      this.registration_tonnage_gross = boat.gross_registered_tonnage;
      if (boat.type_of_propulsion != null)
        if (
          this.items_select_propulsion_type.find(
            (x) => x.abbr == boat.type_of_propulsion
          ) != undefined
        )
          this.select_propulsion_type = this.items_select_propulsion_type.find(
            (x) => x.abbr == boat.type_of_propulsion
          );
      this.main_motor_power = boat.main_engines_power;
      if (boat.power_unit != null)
        if (
          this.items_select_power_unit.find((x) => x.abbr == boat.power_unit) !=
          undefined
        )
          this.select_power_unit = this.items_select_power_unit.find(
            (x) => x.abbr == boat.power_unit
          );
      if (boat.hull_material != null)
        if (
          this.items_select_helmet_material.find(
            (x) => x.abbr == boat.hull_material
          ) != undefined
        )
          this.select_helmet_material = this.items_select_helmet_material.find(
            (x) => x.abbr == boat.hull_material
          );
      this.number_wineries = boat.warehouses_number;
      this.tall_wineries = boat.fish_hold_high;
      this.long_wineries = boat.fish_hold_long;
      this.width_wineries = boat.fish_hold_width;
      this.capacity_wineries = boat.fish_hold_capacity;
      if (boat.fish_hold_type != null)
        if (
          this.items_select_type_conservation_fish.find(
            (x) => x.abbr == boat.fish_hold_type
          ) != undefined
        )
          this.select_type_conservation_fish =
            this.items_select_type_conservation_fish.find(
              (x) => x.abbr == boat.fish_hold_type
            );
      if (boat.construction_country != null)
        if (
          this.items_select_current_state_pavilion.find(
            (x) => x.abbr == boat.construction_country
          ) != undefined
        )
          this.built_country = this.items_select_current_state_pavilion.find(
            (x) => x.abbr == boat.construction_country
          );
      this.administrator_name = boat.beneficial_owner_name;
      this.built_year = boat.year_of_construction;

      this.boat_photo = boat.boat_photo;
      this.boat_photo_details = boat.picture_details;
      this.crew_number = boat.number_of_crew;
      this.manager_operator = boat.manager_operator;
      this.maritime_agent = boat.maritime_agent;
      this.fishermen_number = boat.number_of_fishermens;
      if (boat.fishing_arts.length > 0)
        this.select_fishing_arts_boat = boat.fishing_arts;

      this.fishing_value = boat.fishing_arts_value;
      this.fishing_zone = boat.fishing_zone;
      if (boat.fishing_line != null)
        if (
          this.items_select_type_line.find(
            (x) => x.abbr == boat.fishing_line
          ) != undefined
        )
          this.select_type_line = this.items_select_type_line.find(
            (x) => x.abbr == boat.fishing_line
          );
      this.select_type_line.state = boat.fishing_line_state;
      if (boat.hook_type != null)
        if (
          this.items_select_type_hook.find((x) => x.abbr == boat.hook_type) !=
          undefined
        )
          this.select_type_hook = this.items_select_type_hook.find(
            (x) => x.abbr == boat.hook_type
          );
      if (boat.hook_type_state != null)
        this.select_type_hook.state = boat.hook_type_state;
      this.hook_size_type = boat.hook_size;
      this.hook_quantity = boat.hooks_number;
      this.mother_line_length = boat.mother_line_length;
      if (boat.mother_line_material != null)
        if (
          this.items_select_mother_line_material.find(
            (x) => x.abbr == boat.mother_line_material
          ) != undefined
        )
          this.select_mother_line_material =
            this.items_select_mother_line_material.find(
              (x) => x.abbr == boat.mother_line_material
            );
      if (boat.mother_line_material_state != null)
        this.select_mother_line_material.state =
          boat.mother_line_material_state;
      if (boat.downpipe_material != null)
        if (
          this.items_select_downspout_material.find(
            (x) => x.abbr == boat.downpipe_material
          ) != undefined
        )
          this.select_downspout_material =
            this.items_select_downspout_material.find(
              (x) => x.abbr == boat.downpipe_material
            );
      if (boat.downpipe_material_state != null)
        this.select_downspout_material.state = boat.downpipe_material_state;
      this.total_number_lines = boat.total_lines_number;
      if (boat.art_type_traps != null)
        if (
          this.items_select_denomination_art_fishing.find(
            (x) => x.abbr == boat.art_type_traps
          ) != undefined
        )
          this.select_denomination_art_fishing =
            this.items_select_denomination_art_fishing.find(
              (x) => x.abbr == boat.art_type_traps
            );
      if (boat.art_type_traps_state != null)
        this.select_denomination_art_fishing.state = boat.art_type_traps_state;
      this.cheating_amount = boat.type_traps_number;
      if (boat.type_traps_material != null)
        if (
          this.items_select_main_trap_material.find(
            (x) => x.abbr == boat.type_traps_material
          ) != undefined
        )
          this.select_main_trap_material =
            this.items_select_main_trap_material.find(
              (x) => x.abbr == boat.type_traps_material
            );
      if (boat.type_traps_material_state != null)
        this.select_main_trap_material.state = boat.type_traps_material_state;
      if (boat.type_wound_artifacts != null)
        if (
          this.items_select_type_artifact.find(
            (x) => x.abbr == boat.type_wound_artifacts
          ) != undefined
        )
          this.select_type_artifact = this.items_select_type_artifact.find(
            (x) => x.abbr == boat.type_wound_artifacts
          );
      if (boat.type_wound_artifacts_state != null)
        this.select_type_artifact.state = boat.type_wound_artifacts_state;
      this.number_artifacts = boat.amount_artifacts;
      if (boat.artifacts_material != null)
        if (
          this.items_select_artifact_material.find(
            (x) => x.abbr == boat.artifacts_material
          ) != undefined
        )
          this.select_artifact_material =
            this.items_select_artifact_material.find(
              (x) => x.abbr == boat.artifacts_material
            );
      if (boat.artifacts_material_state != null)
        this.select_artifact_material.state = boat.artifacts_material_state;
      this.radiosFishAggregatingDevice =
        boat.aggregating_devices == 1
          ? "SI"
          : boat.aggregating_devices == 0
          ? "NO"
          : "";
      if (boat.type_fad != null)
        if (
          this.items_select_type_fad.find((x) => x.abbr == boat.type_fad) !=
          undefined
        )
          this.select_type_fad = this.items_select_type_fad.find(
            (x) => x.abbr == boat.type_fad
          );
      if (boat.type_fad_state != null)
        this.select_type_fad.state = boat.type_fad_state;
      this.quantity_fad = boat.fad_amount;
      this.components_fad = boat.fad_components;

      this.date_expedition_patent = boat.date_expedition_patent;
      this.date_expiration_patent = boat.date_expiration_patent;
      this.validity_patent = boat.validity_of_patent;
      this.applicable_values = boat.applicable_values;
    },
    editItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      const index = this.desserts.indexOf(item);
      confirm("Are you sure you want to delete this item?") &&
        this.desserts.splice(index, 1);
    },

    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.desserts[this.editedIndex], this.editedItem);
      } else {
        this.desserts.push(this.editedItem);
      }
      this.close();
    },
    limiter(e) {
      if (e.length > 3) {
        //
        e.pop();
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
