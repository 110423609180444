import {
  getLandingZones,
  getPortsOfLanding,
  getRegistrationPort,
  getFishingArts,
  getTypesOfFishery,
  getAuthorizedArts,
  getFishingArtToFishermen,
} from "./DataSourceService";
import store from "../store";
export const concepto = {
  items_select_propulsion_type: [
    { state: "1. Vela", abbr: "UNO" },
    { state: "2. Remo", abbr: "DOS" },
    { state: "3. Vara", abbr: "TRES" },
    { state: "4. Motor Fuera de Borda", abbr: "CUATRO" },
    { state: "5. Motor Interno", abbr: "CINCO" },
    { state: "7. Desconocido", abbr: "SIETE" },
  ],

  boat_unique_id: { state: "", abbr: "" },
  items_boat_unique_id: [
    { state: "1. OMI", abbr: "UNO" },
    { state: "2. NIC", abbr: "DOS" },
    { state: "3. NINGUNO", abbr: "TRES" },
  ],

  select_fishing_port: { state: "", abbr: "" },
  items_select_fishing_port: [
    { state: "1. SAI", abbr: "UNO" },
    { state: "2. PROVIDENCIA", abbr: "DOS" },
  ],

  select_type_fad: null,
  items_select_type_fad: [
    { state: "FAD Natural", abbr: "1" },
    { state: "FAD Artificial", abbr: "2" },
    { state: "Payao", abbr: "3" },
    { state: "Arrecife artificial", abbr: "4" },
    { state: "Casita cubana", abbr: "5" },
  ],
  select_artifact_material: null,
  items_select_artifact_material: [
    { state: "Acero", abbr: "1" },
    { state: "Hierro", abbr: "2" },
    { state: "Madera", abbr: "3" },
  ],
  select_type_artifact: null,
  items_select_type_artifact: [
    { state: "Arpones", abbr: "1" },
    { state: "Lanzas", abbr: "2" },
    { state: "Flechas", abbr: "3" },
    { state: "Pinchos", abbr: "4" },
    { state: "Horcas", abbr: "5" },
    { state: "Tenazas", abbr: "6" },
  ],
  select_main_trap_material: null,
  items_select_main_trap_material: [
    { state: "Madera y malla metálica", abbr: "1" },
    { state: "Madera y malla plastica", abbr: "2" },
    { state: "Madera y malla en polifilamento", abbr: "3" },
    { state: "Varilla y malla metálica", abbr: "4" },
    { state: "Varilla y malla plastica", abbr: "5" },
    { state: "Varilla y malla en polifilamento", abbr: "6" },
    { state: "Plástico", abbr: "7" },
    { state: "Madera y acero", abbr: "8" },
  ],
  select_denomination_art_fishing: null,
  items_select_denomination_art_fishing: [
    { state: "Nasas para peces", abbr: "1" },
    { state: "Nasas para crustáceos", abbr: "2" },
    { state: "Nasas para moluscos", abbr: "3" },
  ],
  select_downspout_material: null,
  items_select_downspout_material: [
    { state: "Monofilamento", abbr: "1" },
    { state: "Polifilamento", abbr: "2" },
    { state: "Acero", abbr: "3" },
    { state: "Polifilamento", abbr: "5" },
  ],
  select_type_hook: null,
  items_select_type_hook: [
    { state: "Jota", abbr: "1" },
    { state: "Circular", abbr: "2" },
  ],
  select_mother_line_material: null,
  items_select_mother_line_material: [
    { state: "Monofilamento", abbr: "1" },
    { state: "Multifilamento", abbr: "2" },
  ],
  select_type_line: null,
  items_select_type_line: [
    { state: "1. Long line", abbr: "1" },
    { state: "2. Espinel", abbr: "2" },
    { state: "3. Palangre de fondo - real", abbr: "3" },
    { state: "4. Palangre de deriva", abbr: "4" },
    { state: "5. Curricanes", abbr: "5" },
    { state: "6. Ballestilla", abbr: "6" },
    { state: "7. Cordel - Línea de mano", abbr: "7" },
    /*{ state: "98. Otro, Cual?", abbr: "98" }*/
  ],
  select_type_conservation_fish: { state: "", abbr: "" },
  items_select_type_conservation_fish: [
    { state: "1. Fresh – Fresco", abbr: "1" },
    { state: "2. Live – Vivo", abbr: "2" },
    { state: "3. Frozen – Congelado", abbr: "3" },
    // { state: "98. Other – Otro", abbr: "98" },
    { state: "99. Unknown - Desconocido", abbr: "99" },
  ],
  select_helmet_material: { state: "", abbr: "" },
  items_select_helmet_material: [
    { state: "1. Wood - Madera", abbr: "1" },
    { state: "2. Marine Plywood – Madera forrada", abbr: "2" },
    { state: "3. Aluminium – Aluminio", abbr: "3" },
    { state: "4. Iron/Steel - Hierro / Acero", abbr: "4" },
    { state: "5. Fibreglass – Fibra de vidrio", abbr: "5" },
    { state: "6. Rubber – Caucho", abbr: "6" },
    { state: "7. Cement – Cemento", abbr: "7" },
    { state: "99. Unknown - Desconocido", abbr: "99" },
  ],
  select_power_unit: { state: "", abbr: "" },
  items_select_power_unit: [
    { state: "KW. Kilowatt", abbr: "KW" },
    { state: "HP. Horse Power", abbr: "HP" },
    { state: "OT. Other", abbr: "OT" },
  ],

  select_vessel_type: null,
  items_select_vessel_type: [
    { state: "1. TRAWLERS - ARRASTRERO", abbr: "1" },
    { state: "2. SEINERS - CERQUERO", abbr: "2" },
    { state: "3. DREDGERS - RASTRAS", abbr: "3" },
    { state: "4. LIFT NETTERS – REDES IZADAS", abbr: "4" },
    { state: "5. GILLNETTERS - BARCO AGALLERO", abbr: "5" },
    { state: "6. TRAP SETTERS – BARCOS CON TRAMPAS", abbr: "6" },
    { state: "7. LINERS – BARCOS DE LINEA", abbr: "7" },
    {
      state: "8. VESSELS USING PUMPS FOR FISHING - BARCO CON BOMBAS",
      abbr: "8",
    },
    { state: "9. MULTIPURPOSE VESSELS - POLIVALENTES", abbr: "9" },
    {
      state: "10. RECREATIONAL FISHING VESSELS - RECREACIONES",
      abbr: "10",
    },
    { state: "49. FISHING VESSELS NOT SPECIFIED", abbr: "11" },
  ],
  items_select_authorized_landing_port_ind: [
    { nombre: "CP01. BUENAVENTURA", codigo: "UNO" },
    { nombre: "CP02. TUMACO", codigo: "DOS" },
    { nombre: "CP03. TOLU", codigo: "TRES" },
    { nombre: "CP04. CARTAGENA", codigo: "CUATRO" },
    { nombre: "CP05. BARRANQUILLA", codigo: "CINCO" },
    { nombre: "CP07. SAN ANDRES", codigo: "SIETE" },
    { nombre: "CP12. PROVIDENCIA Y SANTA CATALINA", codigo: "DOCE" },
  ],
  items_select_authorized_landing_port: [],
  select_registration_port: null,
  items_select_registration_port: [
    { state: "CP01 BUENAVENTURA", abbr: "CP01" },
    { state: "CP02 TUMACO", abbr: "CP02" },
    { state: "CP03 BARRANQUILLA", abbr: "CP03" },
    { state: "CP04 SANTA MARTA", abbr: "CP04" },
    { state: "CP05 CARTAGENA", abbr: "CP05" },
    { state: "CP06 RIOHACHA", abbr: "CP06" },
    { state: "CP07 SAN ANDRES", abbr: "CP07" },
    { state: "CPO8 TURBO", abbr: "CPO8" },
    { state: "CP09 COVEÑAS", abbr: "CP09" },
    { state: "CP10 BAHIA SOLANO", abbr: "CP10" },
    { state: "CP11 GUAPI", abbr: "CP11" },
    { state: "CP12 PROVIDENCIA", abbr: "CP12" },
    { state: "CP14 PUERTO BOLIVAR", abbr: "CP14" },
    { state: "CP15 PUERTO CARREÑO", abbr: "CP15" },
    { state: "CP16 LETICIA", abbr: "CP16" },
    { state: "CP17 PUERTO INIRIDA", abbr: "CP17" },
    { state: "CP19 PUERTO LEGUIZAMO", abbr: "CP19" },
  ],
  items_select_authorized_arts: [],
  items_select_types_of_fishing: [
    { state: "Seleccionar", abbr: "", codigo: "" },
    { state: "1. Nuevo", abbr: "UNO", codigo: "1" },
    { state: "2. Prorroga", abbr: "DOS", codigo: "2" },
    { state: "3. Duplicado", abbr: "TRES", codigo: "3" },
    { state: "4. Cancelación del Permiso", abbr: "CUATRO", codigo: "4" }
  ],
  items_select_states_of_type_request: [
    { nombre: "1. Langosta", codigo: "UNO" },
    { nombre: "2. Pesca blanca", codigo: "DOS" },
    { nombre: "3. Caracol", codigo: "TRES" },
    { nombre: "4. Multiespecífica", codigo: "CUATRO" },
  ],
  items_vigency_permi: [
    { nombre: "Permiso Vigente", codigo: "active" },
    { nombre: "Permiso Vencido", codigo: "expired" },
    { nombre: "Permiso Cancelado", codigo: "canceled" },
  ],
  items_patent: [
    { nombre: "Patente Vigente", codigo: "active" },
    { nombre: "Patente Vencida", codigo: "expired" },
    { nombre: "Sin Patente", codigo: "withoutPatent" },
  ],

  select_current_state_pavilion: { state: "", abbr: "" },
  items_select_current_state_pavilion: [
    { state: "AFG. Afghanistan", abbr: "AFG" },
    { state: "ALB. Albania", abbr: "ALB" },
    { state: "DZA. Algeria", abbr: "DZA" },
    { state: "ASM. American Samoa", abbr: "ASM" },
    { state: "AND. Andorra", abbr: "AND" },
    { state: "AGO. Angola", abbr: "AGO" },
    { state: "AIA. Anguilla", abbr: "AIA" },
    { state: "ATA. Antarctica", abbr: "ATA" },
    { state: "ATG. Antigua and Barbuda", abbr: "ATG" },
    { state: "ARG. Argentina", abbr: "ARG" },
    { state: "ARM. Armenia", abbr: "ARM" },
    { state: "ABW. Aruba", abbr: "ABW" },
    { state: "AUS. Australia", abbr: "AUS" },
    { state: "AUT. Austria", abbr: "AUT" },
    { state: "AZE. Azerbaijan", abbr: "AZE" },
    { state: "BHS. Bahamas", abbr: "BHS" },
    { state: "BHR. Bahrain", abbr: "BHR" },
    { state: "BGD. Bangladesh", abbr: "BGD" },
    { state: "BRB. Barbados", abbr: "BRB" },
    { state: "BLR. Belarus", abbr: "BLR" },
    { state: "BEL. Belgium", abbr: "BEL" },
    { state: "BLZ. Belize", abbr: "BLZ" },
    { state: "BEN. Benin", abbr: "BEN" },
    { state: "BMU. Bermuda", abbr: "BMU" },
    { state: "BTN. Bhutan", abbr: "BTN" },
    { state: "BO. Bolivia", abbr: "BO" },
    { state: "BOL. Bolivia", abbr: "BOL" },
    { state: "BIH. Bosnia and Herzegovina", abbr: "BIH" },
    { state: "BWA. Botswana", abbr: "BWA" },
    { state: "BVT. Bouvet Island", abbr: "BVT" },
    { state: "BRA. Brazil", abbr: "BRA" },
    { state: "IOT. British Indian Ocean Territory", abbr: "IOT" },
    { state: "BRN. Brunei Darussalam", abbr: "BRN" },
    { state: "BRN. Brunei", abbr: "BRN" },
    { state: "BGR. Bulgaria", abbr: "BGR" },
    { state: "BFA. Burkina Faso", abbr: "BFA" },
    { state: "BDI. Burundi", abbr: "BDI" },
    { state: "KHM. Cambodia", abbr: "KHM" },
    { state: "CMR. Cameroon", abbr: "CMR" },
    { state: "CAN. Canada", abbr: "CAN" },
    { state: "CPV. Cape Verde", abbr: "CPV" },
    { state: "CYM. Cayman Islands", abbr: "CYM" },
    { state: "CAF. Central African Republic", abbr: "CAF" },
    { state: "TCD. Chad", abbr: "TCD" },
    { state: "CHL. Chile", abbr: "CHL" },
    { state: "CHN. China", abbr: "CHN" },
    { state: "CXR. Christmas Island", abbr: "CXR" },
    { state: "CCK. Cocos (Keeling) Islands", abbr: "CCK" },
    { state: "COL. Colombia", abbr: "COL" },
    { state: "COM. Comoros", abbr: "COM" },
    { state: "COG. Congo", abbr: "COG" },
    { state: "CD. Congo", abbr: "CD" },
    { state: "COK. Cook Islands", abbr: "COK" },
    { state: "CRI. Costa Rica", abbr: "CRI" },
    { state: "CIV. CÃ´te d'Ivoire", abbr: "CIV" },
    { state: "CIV. Ivory Coast", abbr: "CIV" },
    { state: "HRV. Croatia", abbr: "HRV" },
    { state: "CUB. Cuba", abbr: "CUB" },
    { state: "CYP. Cyprus", abbr: "CYP" },
    { state: "CZE. Czech Republic", abbr: "CZE" },
    { state: "DNK. Denmark", abbr: "DNK" },
    { state: "DJI. Djibouti", abbr: "DJI" },
    { state: "DMA. Dominica", abbr: "DMA" },
    { state: "DOM. Dominican Republic", abbr: "DOM" },
    { state: "ECU. Ecuador", abbr: "ECU" },
    { state: "EGY. Egypt", abbr: "EGY" },
    { state: "SLV. El Salvador", abbr: "SLV" },
    { state: "GNQ. Equatorial Guinea", abbr: "GNQ" },
    { state: "ERI. Eritrea", abbr: "ERI" },
    { state: "EST. Estonia", abbr: "EST" },
    { state: "ETH. Ethiopia", abbr: "ETH" },
    { state: "FLK. Falkland Islands (Malvinas)", abbr: "FLK" },
    { state: "FRO. Faroe Islands", abbr: "FRO" },
    { state: "FJI. Fiji", abbr: "FJI" },
    { state: "FIN. Finland", abbr: "FIN" },
    { state: "FRA. France", abbr: "FRA" },
    { state: "GUF. French Guiana", abbr: "GUF" },
    { state: "PYF. French Polynesia", abbr: "PYF" },
    { state: "ATF. French Southern Territories", abbr: "ATF" },
    { state: "GAB. Gabon", abbr: "GAB" },
    { state: "GMB. Gambia", abbr: "GMB" },
    { state: "GEO. Georgia", abbr: "GEO" },
    { state: "DEU. Germany", abbr: "DEU" },
    { state: "GHA. Ghana", abbr: "GHA" },
    { state: "GIB. Gibraltar", abbr: "GIB" },
    { state: "GRC. Greece", abbr: "GRC" },
    { state: "GRL. Greenland", abbr: "GRL" },
    { state: "GRD. Grenada", abbr: "GRD" },
    { state: "GLP. Guadeloupe", abbr: "GLP" },
    { state: "GUM. Guam", abbr: "GUM" },
    { state: "GTM. Guatemala", abbr: "GTM" },
    { state: "GGY. Guernsey", abbr: "GGY" },
    { state: "GIN. Guinea", abbr: "GIN" },
    { state: "GNB. Guinea-Bissau", abbr: "GNB" },
    { state: "GUY. Guyana", abbr: "GUY" },
    { state: "HTI. Haiti", abbr: "HTI" },
    { state: "HMD. Heard Island and McDonald Islands", abbr: "HMD" },
    { state: "VAT. Holy See (Vatican City State)", abbr: "VAT" },
    { state: "HND. Honduras", abbr: "HND" },
    { state: "HKG. Hong Kong", abbr: "HKG" },
    { state: "HUN. Hungary", abbr: "HUN" },
    { state: "ISL. Iceland", abbr: "ISL" },
    { state: "IND. India", abbr: "IND" },
    { state: "IDN. Indonesia", abbr: "IDN" },
    { state: "IR. Iran", abbr: "IR" },
    { state: "IRQ. Iraq", abbr: "IRQ" },
    { state: "IRL. Ireland", abbr: "IRL" },
    { state: "IMN. Isle of Man", abbr: "IMN" },
    { state: "ISR. Israel", abbr: "ISR" },
    { state: "ITA. Italy", abbr: "ITA" },
    { state: "JAM. Jamaica", abbr: "JAM" },
    { state: "JPN. Japan", abbr: "JPN" },
    { state: "JEY. Jersey", abbr: "JEY" },
    { state: "JOR. Jordan", abbr: "JOR" },
    { state: "KAZ. Kazakhstan", abbr: "KAZ" },
    { state: "KEN. Kenya", abbr: "KEN" },
    { state: "KIR. Kiribati", abbr: "KIR" },
    { state: "KP. Korea", abbr: "KP" },
    { state: "KR. Korea", abbr: "KR" },
    { state: "KOR. South Korea", abbr: "KOR" },
    { state: "KWT. Kuwait", abbr: "KWT" },
    { state: "KGZ. Kyrgyzstan", abbr: "KGZ" },
    { state: "LAO. Lao People's Democratic Republic", abbr: "LAO" },
    { state: "LVA. Latvia", abbr: "LVA" },
    { state: "LBN. Lebanon", abbr: "LBN" },
    { state: "LSO. Lesotho", abbr: "LSO" },
    { state: "LBR. Liberia", abbr: "LBR" },
    { state: "LBY. Libyan Arab Jamahiriya", abbr: "LBY" },
    { state: "LBY. Libya", abbr: "LBY" },
    { state: "LIE. Liechtenstein", abbr: "LIE" },
    { state: "LTU. Lithuania", abbr: "LTU" },
    { state: "LUX. Luxembourg", abbr: "LUX" },
    { state: "MAC. Macao", abbr: "MAC" },
    { state: "MK. Macedonia", abbr: "MK" },
    { state: "MDG. Madagascar", abbr: "MDG" },
    { state: "MWI. Malawi", abbr: "MWI" },
    { state: "MYS. Malaysia", abbr: "MYS" },
    { state: "MDV. Maldives", abbr: "MDV" },
    { state: "MLI. Mali", abbr: "MLI" },
    { state: "MLT. Malta", abbr: "MLT" },
    { state: "MHL. Marshall Islands", abbr: "MHL" },
    { state: "MTQ. Martinique", abbr: "MTQ" },
    { state: "MRT. Mauritania", abbr: "MRT" },
    { state: "MUS. Mauritius", abbr: "MUS" },
    { state: "MYT. Mayotte", abbr: "MYT" },
    { state: "MEX. Mexico", abbr: "MEX" },
    { state: "FM. Micronesia", abbr: "FM" },
    { state: "MD. Moldova", abbr: "MD" },
    { state: "MCO. Monaco", abbr: "MCO" },
    { state: "MNG. Mongolia", abbr: "MNG" },
    { state: "MNE. Montenegro", abbr: "MNE" },
    { state: "MSR. Montserrat", abbr: "MSR" },
    { state: "MAR. Morocco", abbr: "MAR" },
    { state: "MOZ. Mozambique", abbr: "MOZ" },
    { state: "MMR. Myanmar", abbr: "MMR" },
    { state: "MMR. Burma", abbr: "MMR" },
    { state: "NAM. Namibia", abbr: "NAM" },
    { state: "NRU. Nauru", abbr: "NRU" },
    { state: "NPL. Nepal", abbr: "NPL" },
    { state: "NLD. Netherlands", abbr: "NLD" },
    { state: "ANT. Netherlands Antilles", abbr: "ANT" },
    { state: "NCL. New Caledonia", abbr: "NCL" },
    { state: "NZL. New Zealand", abbr: "NZL" },
    { state: "NIC. Nicaragua", abbr: "NIC" },
    { state: "NER. Niger", abbr: "NER" },
    { state: "NGA. Nigeria", abbr: "NGA" },
    { state: "NIU. Niue", abbr: "NIU" },
    { state: "NFK. Norfolk Island", abbr: "NFK" },
    { state: "MNP. Northern Mariana Islands", abbr: "MNP" },
    { state: "NOR. Norway", abbr: "NOR" },
    { state: "OMN. Oman", abbr: "OMN" },
    { state: "PAK. Pakistan", abbr: "PAK" },
    { state: "PLW. Palau", abbr: "PLW" },
    { state: "PS. Palestinian Territory", abbr: "PS" },
    { state: "PAN. Panama", abbr: "PAN" },
    { state: "PNG. Papua New Guinea", abbr: "PNG" },
    { state: "PRY. Paraguay", abbr: "PRY" },
    { state: "PER. Peru", abbr: "PER" },
    { state: "PHL. Philippines", abbr: "PHL" },
    { state: "PCN. Pitcairn", abbr: "PCN" },
    { state: "POL. Poland", abbr: "POL" },
    { state: "PRT. Portugal", abbr: "PRT" },
    { state: "PRI. Puerto Rico", abbr: "PRI" },
    { state: "QAT. Qatar", abbr: "QAT" },
    { state: "REU. RÃ©union", abbr: "REU" },
    { state: "ROU. Romania", abbr: "ROU" },
    { state: "RUS. Russian Federation", abbr: "RUS" },
    { state: "RUS. Russia", abbr: "RUS" },
    { state: "RWA. Rwanda", abbr: "RWA" },
    { state: "SH. Saint Helena", abbr: "SH" },
    { state: "KNA. Saint Kitts and Nevis", abbr: "KNA" },
    { state: "LCA. Saint Lucia", abbr: "LCA" },
    { state: "SPM. Saint Pierre and Miquelon", abbr: "SPM" },
    { state: "VCT. Saint Vincent and the Grenadines", abbr: "VCT" },
    { state: "VCT. Saint Vincent & the Grenadines", abbr: "VCT" },
    { state: "VCT. St. Vincent and the Grenadines", abbr: "VCT" },
    { state: "WSM. Samoa", abbr: "WSM" },
    { state: "SMR. San Marino", abbr: "SMR" },
    { state: "STP. Sao Tome and Principe", abbr: "STP" },
    { state: "SAU. Saudi Arabia", abbr: "SAU" },
    { state: "SEN. Senegal", abbr: "SEN" },
    { state: "SRB. Serbia", abbr: "SRB" },
    { state: "SYC. Seychelles", abbr: "SYC" },
    { state: "SLE. Sierra Leone", abbr: "SLE" },
    { state: "SGP. Singapore", abbr: "SGP" },
    { state: "SVK. Slovakia", abbr: "SVK" },
    { state: "SVN. Slovenia", abbr: "SVN" },
    { state: "SLB. Solomon Islands", abbr: "SLB" },
    { state: "SOM. Somalia", abbr: "SOM" },
    { state: "ZAF. South Africa", abbr: "ZAF" },
    {
      state: "SGS. South Georgia and the South Sandwich Islands",
      abbr: "SGS",
    },
    { state: "ESP. Spain", abbr: "ESP" },
    { state: "LKA. Sri Lanka", abbr: "LKA" },
    { state: "SDN. Sudan", abbr: "SDN" },
    { state: "SUR. Suriname", abbr: "SUR" },
    { state: "SJM. Svalbard and Jan Mayen", abbr: "SJM" },
    { state: "SWZ. Swaziland", abbr: "SWZ" },
    { state: "SWE. Sweden", abbr: "SWE" },
    { state: "CHE. Switzerland", abbr: "CHE" },
    { state: "SYR. Syrian Arab Republic", abbr: "SYR" },
    { state: "TW. Taiwan", abbr: "TW" },
    { state: "TWN. Taiwan", abbr: "TWN" },
    { state: "TJK. Tajikistan", abbr: "TJK" },
    { state: "TZ. Tanzania", abbr: "TZ" },
    { state: "THA. Thailand", abbr: "THA" },
    { state: "TLS. Timor-Leste", abbr: "TLS" },
    { state: "TGO. Togo", abbr: "TGO" },
    { state: "TKL. Tokelau", abbr: "TKL" },
    { state: "TON. Tonga", abbr: "TON" },
    { state: "TTO. Trinidad and Tobago", abbr: "TTO" },
    { state: "TTO. Trinidad & Tobago", abbr: "TTO" },
    { state: "TUN. Tunisia", abbr: "TUN" },
    { state: "TUR. Turkey", abbr: "TUR" },
    { state: "TKM. Turkmenistan", abbr: "TKM" },
    { state: "TCA. Turks and Caicos Islands", abbr: "TCA" },
    { state: "TUV. Tuvalu", abbr: "TUV" },
    { state: "UGA. Uganda", abbr: "UGA" },
    { state: "UKR. Ukraine", abbr: "UKR" },
    { state: "ARE. United Arab Emirates", abbr: "ARE" },
    { state: "GBR. United Kingdom", abbr: "GBR" },
    { state: "USA. United States", abbr: "USA" },
    { state: "UMI. United States Minor Outlying Islands", abbr: "UMI" },
    { state: "URY. Uruguay", abbr: "URY" },
    { state: "UZB. Uzbekistan", abbr: "UZB" },
    { state: "VUT. Vanuatu", abbr: "VUT" },
    { state: "VE. Venezuela", abbr: "VE" },
    { state: "VEN. Venezuela", abbr: "VEN" },
    { state: "VNM. Viet Nam", abbr: "VNM" },
    { state: "VNM. Vietnam", abbr: "VNM" },
    { state: "VG. Virgin Islands", abbr: "VG" },
    { state: "VI. Virgin Islands", abbr: "VI" },
    { state: "WLF. Wallis and Futuna", abbr: "WLF" },
    { state: "ESH. Western Sahara", abbr: "ESH" },
    { state: "YEM. Yemen", abbr: "YEM" },
    { state: "ZMB. Zambia", abbr: "ZMB" },
    { state: "ZW. Zimbabwe", abbr: "ZWE" },
  ],
  items_select_fishing_arts: [],
  select_fishing_arts_boat: null,
  items_select_fishing_arts_boat: [
    { state: "1. Sedal o de anzuelo", abbr: "UNO" },
    { state: "2. Nasas", abbr: "DOS" },
    { state: "3. Buceo", abbr: "TRES" },
  ],
};
export const consultar = {
  fetch: async () => {
    try {
      if (!store.state.isRunningFetch) {
        store.commit('SET_RUNNING', true);

        if (!store.state.variablesConcepto.items_select_authorized_landing_port) {
          const landingZonesData = (await getLandingZones())?.data || [];
          store.commit('SET_VARIABLES_CONCEPTO', { items_select_authorized_landing_port: landingZonesData });
        }

        if (!store.state.variablesConcepto.items_select_authorized_landing_port_ind) {
          const portsOfLandingData = (await getPortsOfLanding())?.data || [];
          store.commit('SET_VARIABLES_CONCEPTO', { items_select_authorized_landing_port_ind: portsOfLandingData });
        }

        if (!store.state.variablesConcepto.items_select_registration_port) {
          const registrationPortData = (await getRegistrationPort())?.data || [];
          store.commit('SET_VARIABLES_CONCEPTO', { items_select_registration_port: registrationPortData });
        }

        if (!store.state.variablesConcepto.items_select_fishing_arts_boat) {
          const fishingArtsData = (await getFishingArts())?.data || [];
          store.commit('SET_VARIABLES_CONCEPTO', { items_select_fishing_arts_boat: fishingArtsData });
        }

        if (!store.state.variablesConcepto.items_select_fishing_arts) {
          const fishingArtToFishermenData = (await getFishingArtToFishermen())?.data || [];
          store.commit('SET_VARIABLES_CONCEPTO', { items_select_fishing_arts: fishingArtToFishermenData });
        }

        if (!store.state.variablesConcepto.items_select_types_of_fishing) {
          const typesOfFishingData = (await getTypesOfFishery())?.data || [];
          store.commit('SET_VARIABLES_CONCEPTO', { items_select_types_of_fishing: typesOfFishingData });

        }else
        if (!store.state.variablesConcepto.items_select_authorized_arts) {
          const authorizedArtsData = (await getAuthorizedArts())?.data || [];
          store.commit('SET_VARIABLES_CONCEPTO', { items_select_authorized_arts: authorizedArtsData });
        }

        store.commit('SET_RUNNING', false);
      }


      return {
        items_select_authorized_landing_port: store.state.variablesConcepto.items_select_authorized_landing_port,
        items_select_authorized_landing_port_ind: store.state.variablesConcepto.items_select_authorized_landing_port_ind,
        items_select_registration_port: store.state.variablesConcepto.items_select_registration_port,
        items_select_fishing_arts_boat: store.state.variablesConcepto.items_select_fishing_arts_boat,
        items_select_fishing_arts: store.state.variablesConcepto.items_select_fishing_arts,
        items_select_types_of_fishing: store.state.variablesConcepto.items_select_types_of_fishing,
        items_select_authorized_arts: store.state.variablesConcepto.items_select_authorized_arts,
      };
    } catch (error) {
      store.commit('SET_RUNNING', false);
      throw error;
    }
  },



};

